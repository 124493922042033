import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import 'leaflet/dist/leaflet.css';

import { BrowserRouter, Redirect } from "react-router-dom";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import CookiePolicy from "./components/CookiePolicy";
import Methodology from "./components/Methodology";
import { Helmet } from 'react-helmet';
import { META_DESCRIPTION } from './consts';

function JobsRouter() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Circular Jobs</title>
                <meta name="description" content={META_DESCRIPTION} />
            </Helmet>
            <BrowserRouter basename="/">
                <Switch>
                    <Route exact path={'/cookies'} component={CookiePolicy} />
                    <Route exact path={'/methodology'} component={Methodology} />
                    <Route exact path={'/'} component={App} />
                    <Route path={'/:locationLevel/:locationId'} component={App} />
                    <Redirect to="/" />
                </Switch>
            </BrowserRouter>
        </>
    );
}


ReactDOM.render(<JobsRouter/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
