import React from 'react'
import './Legend.css'
import Card from '@material-ui/core/Card';
import NumberFormat from 'react-number-format';


function LegendComponent(props) {
  return (
    <Card className="legend-wrapper">
      <div className="value-pallet">
        <div className="value">
          <NumberFormat value={props.max} displayType={'text'} thousandSeparator={true} /> {props.unit}
        </div>
        <div className="value">
          <NumberFormat value={props.frontier} displayType={'text'} thousandSeparator={true} /> {props.unit}
        </div>
        <div className="value">{0}</div>
      </div>
      <div className="color-pallet-wrapper">
        <div className="color-pallet-2"></div>
        <div className="color-pallet-1"></div>
      </div>
    </Card>
  );
}

export default LegendComponent;