import React, { Component } from 'react'
import './App.css';
import HeaderComponent from './Header/Header';
import MapComponent from './Map/Map';
import MethodologyExplanation from './MethodologyExplanation/MethodologyExplanation'
import ShareLink from './Map/ShareLink/ShareLink'

class App extends Component {
  constructor(props) {
    const urlParams = new URLSearchParams(props.location.search);
    const params = {
      locationLevel: urlParams.get('location-level'),
      locationId: urlParams.get('location-id'),
      showInfoPane: urlParams.get('show-info-pane'),
      setScope: urlParams.get('set-scope')
    }
  
    super(props);

    this.state = {
      params: params,
      openExplanation: true,
      openShareLink: false,
      tab: 1
    }
  }



  handleOpenExplanation = (res) => {
    this.setState({ openExplanation: res.open });
    if (res.tab) {
      this.setState({ tab: +res.tab })
    }
  };

  handleOpenShareLink = () => {
    this.setState(state => ({openShareLink: !state.openShareLink}))
  }

  render() {
    return (
      <div className="App">
        <HeaderComponent handleOpenExplanation={this.handleOpenExplanation} />
        <MapComponent handleOpenExplanation={this.handleOpenExplanation} handleOpenShareLink={this.handleOpenShareLink} params={this.state.params} />
        <MethodologyExplanation tab={this.state.tab} open={this.state.openExplanation} openExplanation={this.handleOpenExplanation} />
        <ShareLink open={this.state.openShareLink} openShare={this.handleOpenShareLink}/>
      </div>
    );
  }

}

export default App;
