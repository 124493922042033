import React, { Component } from 'react'
import './Datatable.css'

import _ from 'lodash';
import NumberFormat from 'react-number-format';


class DatatableComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            datatable: this.props.datatable
        }
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({ datatable: nextProps.datatable });
    }


    render() {
        return (
            <div className="datatable-component">
                {
                    this.state.datatable.map((s, i) => {
                        return <div key={i} className="datatable-sector">
                            <div className="sector-name">
                                <span><span className="bold">{s.sectorid}</span> - {s.sector}</span>
                            </div>
                            <div className="sector-circularjobs">
                                <NumberFormat value={s.circularjobs} displayType={'text'} thousandSeparator={true} />
                            </div>
                            <div className="sector-percent">
                                {_.round((s.circularjobs / s.totaljobs) * 100, 1) || '0'}% of {s.sectorid}
                            </div>
                        </div>
                    })
                }
            </div>
        );
    }
}

export default DatatableComponent;