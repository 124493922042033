const listeners = [];
let state = {

}

export {
    onEventSubscribe,
    onDispatchEvent
};

function onEventSubscribe(cb) {
    if(cb) listeners.push(cb);
    else listeners.forEach(f => f(state));
}

function onDispatchEvent(event, value) {
    state[event] = value;
    listeners.forEach(f => f(state));
}