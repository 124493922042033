import React, { Component } from 'react'
import './Header.css'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar'
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import axios from 'axios';
import { selectFromSearch } from '../../services/selectedGeoObject';
import { onEventSubscribe } from '../../services/events'
import { isMobile, isBrowser } from "react-device-detect";

import ReactSVG from 'react-svg'
import tableIcon from '../../assets/icons/table.svg';
import infoIcon from '../../assets/icons/info.svg';

class HeaderComponent extends Component {
    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.state = {
            printMod: false,
            searchResponse: [],
            cursor: 0
        };

        onEventSubscribe((state) => {
            this.setState({ printMod: state.print })
        })
    }
    handleSearchQuery = (e) => {
        let searchQuery = e.target.value.trim();
        if (searchQuery) {
            axios.get(`/api/search?query=${searchQuery}`).then(res => {
                this.setState({ searchResponse: res.data, cursor: 0 });
            })
        } else {
            this.setState({ searchResponse: [] });
        }
    };

    onSelect = (selectedItem) => {
        this.setState({ searchResponse: [] });
        this.searchInput.value = '';
        selectFromSearch(selectedItem);
    };

    handleKeyDown(e) {
        if (e.keyCode === 38 || e.keyCode === 40) e.preventDefault();

        const { cursor, searchResponse } = this.state;
        if (e.keyCode === 13 && searchResponse.length > 0) {
            this.onSelect(searchResponse[cursor]);
        }

        if (e.keyCode === 38 && cursor > 0) {
            this.setState(prevState => ({
                cursor: prevState.cursor - 1
            }))
        } else if (e.keyCode === 40 && cursor < searchResponse.length - 1) {
            this.setState(prevState => ({
                cursor: prevState.cursor + 1
            }))
        }

        let searchListEl = document.querySelector('.search-list');
        if (searchListEl) {
            searchListEl.scrollTop = searchListEl.clientHeight + 36 * (cursor - 9);
        }
    }

    openExplanation() {
        this.props.handleOpenExplanation({ open: true, tab: "1" });
    }

    render() {
        return !this.state.printMod && (<AppBar position="static" className="header no-print">
            <Toolbar className="header-toolbar">
                <div className="title-wrapper">
                    <p className="subtitle">Circular Jobs Monitor</p>
                </div>
                <div className="flex-right">
                    <div className="info-wrapper has-label no-print" onClick={() => { this.openExplanation() }}>
                      <div aria-label="Information">
                        { isMobile ?
                          <ReactSVG src={infoIcon} svgStyle={{ width: 22, height: 22 }} /> :
                          <Button className="button">Learn</Button>
                        }
                      </div> 
                    </div>
                    {
                        isBrowser && (<a className="download-wrapper has-label no-print" href="/api/download" target="_blank">
                        <div aria-label="Download data in Excel">
                            <ReactSVG src={tableIcon} svgStyle={{ width: 22, height: 22 }} />
                        </div>
                    </a>)
                    }
                    <div className="search">
                        <div className="searchIcon">
                            <SearchIcon />
                        </div>
                        <InputBase className="inputInput inputRoot" onChange={this.handleSearchQuery} inputRef={el => this.searchInput = el} onKeyDown={this.handleKeyDown} />
                        {
                            (this.searchInput && this.searchInput.value) && (<div className="search-list-wrapper">
                                <List component="nav" className="search-list">
                                    {this.state.searchResponse && (this.state.searchResponse.map((r, i) =>
                                        <ListItem className={`search-item ${this.state.cursor === i ? 'active' : ''}`} key={`${r.type}_${r.id}`} onClick={() => this.onSelect(r)}>
                                            {r.name}&nbsp;<span className="type">{r.type}</span>
                                        </ListItem>))}

                                    {(this.state.searchResponse && this.state.searchResponse.length === 0) && (<ListItem className="search-item">
                                        No results
                            </ListItem>)}
                                </List>
                            </div>)
                        }
                    </div>
                </div>
            </Toolbar>
        </AppBar>)
    }
}

export default HeaderComponent