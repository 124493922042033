import React, {useRef} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import './MethodologyExplanation.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { isMobile } from "react-device-detect";

import ce_logo from '../../assets/ce-logo.png'
import unep from '../../assets/unep.png'
import e_hero from '../../assets/e-hero.png'
import goldschmedig from '../../assets/goldschmedig.png'
import kbs from '../../assets/kbs.png'
import youtube_thumbnail from '../../assets/youtube-thumbnail.png'
import close from '../../assets/icons/close.png';
import scroll from '../../assets/icons/arrow-up.png';

import ReactSVG from 'react-svg'

import LearnMoreComponent from './LearnMore';

const useStyles = makeStyles(theme => ({
    root: {
        display: isMobile ? 'block' : 'flex',
        flexGrow: 1,
        width: '100%',
        backgroundColor: '#FFF',
        height: '100%'
    },
}));

const openInNewTab = (url) => window.open(url, "_blank");

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const tabs = [
    'The Circular Jobs Monitor',
    'Who can benefit from the tool',
    'Key concepts and methodology',
    'Partners'
];

function TabWrapper(props) {
    const classes = useStyles();
    const { value } = props;
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView();

    const contents = [
        <Box className="tab-panel" style={{marginBottom: '107px'}}>
            <Typography className="special" variant="h1" style={{marginTop: isMobile? '42px' : '77px'}}>Welcome to the</Typography>
            <Typography className="special bottom" variant="h1">Circular Jobs Monitor!</Typography>
            <Typography variant="body1" style={{marginTop: '57px'}}>The workforce is a key lever for transformation towards the circular economy. To tap into this potential, we need data and evidence. Which jobs are already part of the circular economy? Where are these jobs located? How are these jobs distributed across sectors and how are they related to circular strategies? <br/><br/>The Circular Jobs Monitor provides answers to these questions by gathering and displaying data  on the number, range and location of jobs that are part of the circular economy around the world. It provides policymakers, economists, and labour organisations with insights into the relationship between circular economy activities and the labour market across economic sectors.</Typography>
            <Typography variant="body1" style={{marginTop: '27px'}}>The Circular Jobs Monitor is a product of Circle Economy and part of a joint initiative of <span className='highlight'>Circle Economy</span> and the <span className='highlight'>United Nations Environment Programme</span>.</Typography>
            <Box style={{display: 'flex', alignItems: 'center', marginTop: isMobile ? '12px' : '60px'}}>
                <img onClick={() => openInNewTab('https://www.circle-economy.com/')} src={ce_logo} style={{width: '144px', height: '43px', marginRight: isMobile ? '4px' : '46px', cursor: 'pointer'}} />
                <img onClick={() => openInNewTab('https://www.unep.org/')} src={unep} style={{width: '175px', height: '85px', cursor: 'pointer'}} />
            </Box>
            <img 
                onClick={() => openInNewTab('https://www.youtube.com/watch?v=y-QrS9yoToI&feature=youtu.be')} 
                src={youtube_thumbnail} 
                style={{width: isMobile?'312px':'502px', height: isMobile?'171px':'262px', cursor: 'pointer', marginTop: '67px'}} 
            />
            { isMobile && <img className="scroll-btn" src={scroll} onClick={executeScroll} /> }
        </Box>,
        <Box className="tab-panel" style={{paddingTop: isMobile ? '-15px' : ''}}>
            <Typography variant="h1">Who can benefit from the tool</Typography>
            <Typography variant="body1" style={{marginTop: '31px'}}>Policymakers can use this tool:</Typography>
            <ul>
                <li>As a starting point to define which sectors should be included in circular strategy development</li>
                <li>To monitor the progress of circular economy policies and impact on the labour market, when data is updated regularly</li>
            </ul>
            <Typography variant="body1" style={{marginTop: '62px'}}>Economists, labour organisations and social partners can use this tool to:</Typography>
            <ul style={{marginBottom: '77px'}}>
                <li>Access data</li>
                <li>Understand the geographic distribution of circular jobs</li>
                <li>Align their efforts with the skills needs of different regions, industries and sectors</li>
                <li>Attain a baseline for defining sectors for scenario modelling </li>
            </ul>
            { isMobile && <img className="scroll-btn" src={scroll} onClick={executeScroll} /> }
        </Box>,
        <Box className="tab-panel" style={{paddingTop: isMobile ? '-7px' : '', marginBottom: '94px'}}>
            <Typography variant="h1">Key concepts and methodology</Typography>
            <Typography variant="h2">The Circular Economy</Typography>
            <Typography variant="body1">The circular economy is a solutions framework that can help drive the systemic transformation of our society. Its core tenants design out waste and pollution, keep products and materials in use and regenerate natural systems. In safeguarding our environment and natural resources, circularity also brings with it co-benefits, including the opportunity to generate and redistribute employment.</Typography>
            <Typography variant="h2">Key elements of the circular economy</Typography>
            <Typography variant="body1">Circle Economy's Key Elements framework is a conceptual framework of eight elements of circularity that can be applied at different intervention levels (for example, national, regional, sector, business, product, process, or material) towards a circular economy.</Typography>
            <Typography variant="body1">The Key Elements framework consists of three core elements and five enabling elements. Core elements deal with physical flows directly, whilst enabling elements deal with creating the conditions or removing barriers, for a circular transition.</Typography>
            <LearnMoreComponent url="https://www.circle-economy.com/resources/the-key-elements-of-the-circular-economy-framework" />
            <Typography variant="h2">Circular Job</Typography>
            <Typography variant="body1">A circular job is any occupation that directly involves or indirectly supports one of the key elements of the circular economy. This tool differentiates between three types of circular jobs: core, enabling and directly circular jobs.</Typography>
            <LearnMoreComponent url="https://www.circle-economy.com/circular-jobs-initiative/circular-jobs" />
            <Typography variant="h2">Circular Jobs Methodology</Typography>
            <Typography variant="body1">The Circular Jobs Monitor displays the results of data analysed using the Circular Jobs Methodology. This methodology was developed by Circle Economy and the United Nations Environment Programme. It classifies jobs as core circular, enabling circular or indirectly circular, based on classifying economic sectors of activity according to the Key Elements Framework and studying their interactions using Input Output Analysis. </Typography>
            <LearnMoreComponent url="http://www.circle-economy.com/resources/circular-jobs-methodology"  />
            { isMobile && <img className="scroll-btn" src={scroll} onClick={executeScroll} /> }
        </Box>,
        <Box className="tab-panel" style={{paddingTop: isMobile ? '-8px' : '', marginBottom: '52px'}}>
            <Typography variant="h1">Partners</Typography>
            <Box style={{display: 'flex', alignItems: 'center', marginTop: isMobile ? '12px' : '60px'}}>
                <img onClick={() => openInNewTab('https://www.circle-economy.com/')} src={ce_logo} style={{width: '144px', height: '43px', marginRight: isMobile ? '4px' : '46px', cursor: 'pointer'}} />
                <img onClick={() => openInNewTab('https://www.unep.org/')} src={unep} style={{width: '175px', height: '85px', cursor: 'pointer'}} />
            </Box>
            <Typography variant="body1" style={{marginTop: '77px'}}>
                Knowledge Partner: <span className='highlight' onClick={() => openInNewTab('https://www.eur.nl/en/ehero')}>The Erasmus Happiness Economics Research Organisation (EHERO)</span>
            </Typography>
            <img onClick={() => openInNewTab('https://www.eur.nl/en/ehero')} src={e_hero} style={{width: '165px', height: '114px', cursor: 'pointer'}} />
            <Typography variant="body1" style={{marginTop: '52px'}}>Funding partners: <span className='highlight'>The King Baudouin Foundation and Goldschmeding Foundation</span></Typography>
            <Box style={{display: 'flex', alignItems: 'center', marginTop: '17px', flexDirection: isMobile ? 'column' : 'row'}}>
                <img onClick={() => openInNewTab('https://www.kbs-frb.be/en/')} src={kbs} style={{width: '169px', height: '85px', cursor: 'pointer'}} />
                <img onClick={() => openInNewTab('https://goldschmeding.foundation/en/')} src={goldschmedig} style={{width: '191px', height: '87px', marginRight: '14px', cursor: 'pointer', marginTop: isMobile ? '12px': 0}} />
            </Box>
            <Typography variant="h1" style={{marginTop: isMobile ? '42px' : '91px'}}>Become a partner</Typography>
            <Typography variant="body1" style={{marginTop: '10px'}}>We work continuously to add new territories to the Circular Jobs Monitor and to deepen its explanatory power. Would you like to help us grow the tool? Get in touch today to explore ways of collaborating with us.</Typography>
            <LearnMoreComponent url="https://www.circle-economy.com/circular-jobs-initiative/contact"/>
            { isMobile && <img className="scroll-btn" src={scroll} onClick={executeScroll} /> }
        </Box>,
    ];

    const tabTap =(e, v) => {
        v == tabs.length+1 ? props.handleClose() : props.handleChange(e, v);
    }

    return <div className={classes.root}>
        <Tabs  ref={myRef}
            orientation="vertical" 
            value={value} 
            onChange={tabTap} 
            sx={{width: '120px'}}
            TabIndicatorProps={{style:{ display: 'none' }}}
        >
            <Tab disabled className="heading-tab" style={{marginBottom: '7px'}} label='Learn About:' key={-1} />)
            { tabs.map((tab, index) => <Tab style={{marginTop: '7px'}} className={value === index+1? 'active' :''} key={index} label={tab} {...props.a11yProps(index)} />) }
            <Tab className="heading-tab" style={{marginTop: '21px'}} label='< Go to the monitor' />)
        </Tabs>
        { ['', ...contents, ''].map((tab, index) => 
            <TabPanel 
                className={`scrolltab ${isMobile ? 'mobile' : ''}`} 
                value={index} index={value}>{ tab }
            </TabPanel>)}
    </div>;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

class MethodologyExplanation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            value: this.props.tab || 1
        }
        this.handleChange = this.handleChange.bind(this);
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({ open: nextProps.open, value: nextProps.tab || 1 });
    }

    handleClose = () => {
        this.props.openExplanation({ open: false });
        const active = document.querySelectorAll('.filter-block.is-active');
        if (active && active.length) {
            active[0].classList.remove('is-active');
        }
    };

    render() {
        const { value } = this.state;
        return (
            <Dialog
                className="dialog"
                open={this.state.open}
                TransitionComponent={Transition}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={isMobile}
                PaperProps={{
                    sx: { 
                        position: 'fixed',
                        right: 0,
                        top: 0,
                        maxHeight: '100vh',
                    }
                }}
            >

                <DialogContent className="methodology-explanation-dialog" style={{height: '100vh'}}>
                    <TabWrapper value={value} a11yProps={this.a11yProps} handleChange={this.handleChange} handleClose={this.handleClose} />
                </DialogContent>
                <div className="close-btn" onClick={this.handleClose}>
                    <img src={close} style={{width: '24px', height: '24px', margin: '6px'}} alt={'close'}/>
                </div>
            </Dialog>
        );
    }
}

export default MethodologyExplanation;