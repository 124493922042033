import React, { Component } from 'react';

import _ from 'lodash';

import './List.css';
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Card from '@material-ui/core/Card';

import ReactSVG from 'react-svg';

import regenerativeIcon from '../../../../assets/icons/regenerative.svg';
import preserveIcon from '../../../../assets/icons/preserve.svg';
import rethinkIcon from '../../../../assets/icons/rethink.svg';
import wasteIcon from '../../../../assets/icons/waste.svg';
import collaborateIcon from '../../../../assets/icons/collaborate.svg';
import designIcon from '../../../../assets/icons/design.svg';
import digitalIcon from '../../../../assets/icons/digital.svg';
import alertCircle from '../../../../assets/icons/alert-circle.svg';
import scopeArrow from '../../../../assets/icons/scope-arrow.png'
import closeIcon from '../../../../assets/icons/mobile-close.svg';

import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

import Radio from '@material-ui/core/Radio';

import { getScopeDataState, getSelectScopes } from '../../../../services/scopeData';

const styles = theme => ({
    root: {
        color: '#e8e8e8',
        '&$checked': {
            color: '#3876B7',
        },
    },
    checked: {},
});

class ListComponent extends Component {
    levels = ['Country', 'Region', 'Province', 'Municipality'];
    
    state = {
        open: false,
        type: this.props.type,
        openPosition: null,
        currentLevel: this.props.level,
        scope: getScopeDataState(this.type).scope,
        scopes: getSelectScopes(this.type),
        selected: false,
        showAddBtn: true
    }

    constructor(props) {
        super(props);
    }

    componentWillMount = (s) => {
        this.setState({ selected: this.state.scope.country.enable })
    }


    onLevelClick(level) {
        if (level === this.state.currentLevel) return;
        this.setState({ currentLevel: level });
        this.props.onZoomLevelSelected(level.toLowerCase(), true);
        this.props.handleClose({
            block: 'maplevel',
            level: level
        })
    }

    handleToggle(_type, _add) {
        if (_add) {
            _type++;
            this.setState(state => ({ selected: false }));
        } else {
            this.setState(state => ({ selected: true }));
        }
        
        if (typeof _type !== 'undefined') {
            getSelectScopes(_type).length && this.setState(state => ({ type: _type, scopes: getSelectScopes(_type) }));
        } else {
            this.setState(state => ({ scopes: getSelectScopes() }));
        }

        if(_type > -1 && _add) {
            this.setState({showAddBtn: false})
        }
    };


    onChangeFilter = (id, subId, title, label) => {
        let innerHtml = document.getElementsByClassName('filterby-label')[label].innerHTML;
        if(label === 4){
            innerHtml = 'Use <b>Waste</b><br/>as a Resource'
        }
        if(label === 7){
            innerHtml = '<b>Design</b><br/>for the Future'
        }
        this.props.handleClose({
            block: 'filter',
            id: id,
            subId: subId,
            title: title,
            innerHtml: innerHtml
        })
    }

    onScopeClick(_scope, _type) {
        if (_type === 1) {
            this.setState({ selected: false });
        }
        this.setState(state => ({ type: _type, scopes: getSelectScopes(_type), selected: true }));
        const scope = {
            id: _scope.id,
            name: _scope.value,
            type: { 0: 'world', 1: 'country', 2: 'region' }[_type]
        }
        this.props.handleClose({ block: 'scope', scope: scope });
    }

    changeScope(scope) {
        this.setState({ selected: true, showAddBtn: true });
        this.props.handleClose(scope);
    }

    openExplanation() {
        this.props.handleOpenExplanation({ open: true, tab: "1"});
    }


    render() {
        const { classes, handleClose } = this.props;
        return (
            <Popper open={this.props.open} anchorEl={this.props.anchorEl} transition disablePortal className="scope-tooltip" >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>

                        <Paper>
                            <ClickAwayListener onClickAway={this.props.handleClose}>
                                <Card className="scope-list-wrapper">
                                    <div className="close-btn" onClick={() => handleClose({action: 'close'})}><ReactSVG src={closeIcon} svgStyle={{ height: 12, width: 12, fill: '#263238' }}></ReactSVG></div>
                                    {
                                        this.props.block === 'scope' &&
                                        <div>
                                            <div className="scope-list">
                                                <div className={classNames('scope world-scope', { show: this.state.scope.world.enable, current: !this.state.scope.country.enable })} key={`0_world`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.onScopeClick(this.state.scope.world, 0)
                                                    }}>
                                                    <span className="value">{this.state.scope.world.value}</span>
                                                    <span className="next">
                                                        <img className="scope-arrow" src={scopeArrow} alt="" />
                                                    </span>
                                                </div>
                                                <div className={classNames('scope country-scope', { show: this.state.scope.country.enable, hide: !this.state.scope.country.enable, current: !this.state.scope.region.enable })} key={`1_${this.state.scope.country.value}_${this.state.scope.country.id}`}
                                                    onClick={(e) => {
                                                        if (this.state.scope.region.enable) {
                                                            e.stopPropagation();
                                                            this.onScopeClick(this.state.scope.country, 1)
                                                        } else {
                                                            this.handleToggle(1)
                                                        }
                                                    }}>
                                                    <span className="value">{this.state.scope.country.value}</span>
                                                    <span className="next">
                                                        <img className="scope-arrow" src={scopeArrow} alt="" />
                                                    </span>
                                                </div>

                                                <div className="scope-item-wrapper">
                                                    <div className={classNames('scope region-scope', { show: this.state.scope.region.enable, hide: !this.state.scope.region.enable, current: this.state.scope.region.enable && !this.state.scope.province.enable })} key={`2_${this.state.scope.region.value}_${this.state.scope.region.id}`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (this.state.scope.province.enable) {
                                                                this.onScopeClick(this.state.scope.region, 2)
                                                            } else {
                                                                this.handleToggle(2)
                                                            }
                                                        }}>
                                                        <span className="value">{this.state.scope.region.value}</span>
                                                        <span className="next">
                                                            <img className="scope-arrow" src={scopeArrow} alt="" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="scope-item-wrapper">
                                                    <div className={classNames('scope province-scope', { show: this.state.scope.province.enable, hide: !this.state.scope.province.enable, current: this.state.scope.province.enable })}
                                                        onClick={() => this.handleToggle(3)} key={`3_${this.state.scope.province.value}_${this.state.scope.province.id}`}>
                                                        <span className="value">{this.state.scope.province.value}</span>{this.state.scope.province.enable}
                                                    </div>
                                                </div>
                                                <div className="scope-add">
                                                    {
                                                        !this.state.scope.province.enable && this.state.type !== 3 && this.state.showAddBtn && (
                                                            <IconButton aria-label="Add" disableRipple onClick={(e) => {
                                                                e.stopPropagation();
                                                                if(this.state.scopes.length && {'country': 1, 'region': 2, 'province': 3, 'nunicipality': 4}[this.state.scopes[0].type] === this.state.type && !this.state.selected) {
                                                                    return;
                                                                }
                                                                this.handleToggle(this.state.type, true)
                                                            }}>
                                                                <AddIcon fontSize="small" />
                                                            </IconButton>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            {
                                                this.state.scopes && this.state.scopes.length > 0 && (
                                                    <div className="scope-content">
                                                        <div className={classNames('scope-select',
                                                            {
                                                                'country-margin': this.state.scopes.length && this.state.scopes[0].type === 'country',
                                                                'region-margin': this.state.scopes.length && this.state.scopes[0].type === 'region',
                                                                'province-margin': this.state.scopes.length && this.state.scopes[0].type === 'province'
                                                            })
                                                        }
                                                        >
                                                            {
                                                                _.sortBy(this.state.scopes, s => s.name).map(s => {
                                                                    return (
                                                                        <div className="scope-list-item" name={s.name} key={s.id} onClick={() => this.changeScope({ block: 'scope', scope: s, type: this.state.type })}>
                                                                            <Radio
                                                                                className="filter-radio"
                                                                                color="primary"
                                                                                checked={this.props.scopeName === s.name}
                                                                                value={s.name}
                                                                                name="radio-button-demo"
                                                                                classes={{
                                                                                    root: classes.root,
                                                                                    checked: classes.checked,
                                                                                }}
                                                                            />
                                                                            {s.name}
                                                                        </div>)
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            <div className="footer">
                                                <div className="footer-icon">
                                                    <ReactSVG src={alertCircle} svgStyle={{ height: 17, width: 17, fill: '#A0A8B1', padding: '0 12px' }}></ReactSVG>
                                                </div>
                                                <div className="footer-text">
                                                    Scope limits the map to the selected country / region / province and allows to see Top 5 within that geography
                                                    </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        this.props.block === 'maplevel' && (
                                            <div className="maplevel">
                                                {this.levels.map(level => {
                                                    return (
                                                        <div key={level} className='level-item' onClick={() => this.onLevelClick(level)}>
                                                            <Radio
                                                                className="filter-radio"
                                                                color="primary"
                                                                checked={this.props.level.toLowerCase() === level.toLowerCase()}
                                                                value={level.toLowerCase()}
                                                                name="radio-button-demo"
                                                                classes={{
                                                                    root: classes.root,
                                                                    checked: classes.checked,
                                                                }}
                                                            />
                                                            {level}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )
                                    }

                                    {
                                        this.props.block === 'filter' && (
                                            <div className="filter">
                                                <div className="filter-content">
                                                    <div className='filter-item group' onClick={() => this.onChangeFilter(0, -1, 'All Circular Jobs', 0)}>
                                                        <Radio
                                                            className="filter-radio"
                                                            color="primary"
                                                            checked={this.props.filter.jobsType === 'total'}
                                                            name="radio-button-demo"
                                                            classes={{
                                                                root: classes.root,
                                                                checked: classes.checked,
                                                            }}
                                                        />
                                                        <div className="filterby-label blue"><b>All Circular Jobs</b></div>
                                                    </div>

                                                    <div className='filter-item group' onClick={() => this.onChangeFilter(1, -1, 'Core Circular Jobs', 1)}>
                                                        <Radio
                                                            className="filter-radio"
                                                            color="primary"
                                                            checked={this.props.filter.jobsSubType && this.props.filter.jobsType === 'core' && this.props.filter.jobsSubType.length === 3}
                                                            name="radio-button-demo"
                                                            classes={{
                                                                root: classes.root,
                                                                checked: classes.checked,
                                                            }}
                                                        />
                                                        <div className="filterby-label blue"><b>Core Circular Jobs</b></div>
                                                    </div>

                                                    <div className='filter-item' onClick={() => this.onChangeFilter(1, 0, 'Prioritize Regenerative Resources', 2)}>
                                                        <Radio
                                                            className="filter-radio"
                                                            color="primary"
                                                            checked={this.props.filter.jobsType === 'core' && this.props.filter.jobsSubType.includes(0)}
                                                            name="radio-button-demo"
                                                            classes={{
                                                                root: classes.root,
                                                                checked: classes.checked,
                                                            }}
                                                        />
                                                        <ReactSVG src={regenerativeIcon} svgStyle={{ width: 25, fill: '#A0A8B1' }}></ReactSVG>
                                                        <div className="filterby-label">Prioritize <b>Regenerative</b> Resources</div>
                                                    </div>


                                                    <div className='filter-item' onClick={() => this.onChangeFilter(1, 1, "Sustain and Preserve What's Already There", 3)}>
                                                        <Radio
                                                            className="filter-radio"
                                                            color="primary"
                                                            checked={this.props.filter.jobsType === 'core' && this.props.filter.jobsSubType.includes(1)}
                                                            name="radio-button-demo"
                                                            classes={{
                                                                root: classes.root,
                                                                checked: classes.checked,
                                                            }}
                                                        />
                                                        <ReactSVG src={preserveIcon} svgStyle={{ width: 25, fill: '#A0A8B1' }}></ReactSVG>
                                                        <div className="filterby-label"><b>Sustain and Preserve</b> What's Already There</div>
                                                    </div>


                                                    <div className='filter-item' onClick={() => this.onChangeFilter(1, 2, 'Use Waste as a Resource', 4)}>
                                                        <Radio
                                                            className="filter-radio"
                                                            color="primary"
                                                            checked={this.props.filter.jobsType === 'core' && this.props.filter.jobsSubType.includes(2)}
                                                            name="radio-button-demo"
                                                            classes={{
                                                                root: classes.root,
                                                                checked: classes.checked,
                                                            }}
                                                        />
                                                        <ReactSVG src={wasteIcon} svgStyle={{ width: 25, fill: '#A0A8B1' }}></ReactSVG>
                                                        <div className="filterby-label">Use <b>Waste</b> as a Resource</div>
                                                    </div>


                                                    <div className='filter-item group' onClick={() => this.onChangeFilter(2, -1, 'Enabling Circular Jobs', 5)}>
                                                        <Radio
                                                            className="filter-radio"
                                                            color="primary"
                                                            checked={this.props.filter.jobsSubType && this.props.filter.jobsType === 'enabling' && this.props.filter.jobsSubType.length === 4}
                                                            name="radio-button-demo"
                                                            classes={{
                                                                root: classes.root,
                                                                checked: classes.checked,
                                                            }}
                                                        />
                                                        <div className="filterby-label blue"><b>Enabling Circular Jobs</b></div>
                                                    </div>


                                                    <div className='filter-item' onClick={() => this.onChangeFilter(2, 0, 'Rethink the Business Model', 6)}>
                                                        <Radio
                                                            className="filter-radio"
                                                            color="primary"
                                                            checked={this.props.filter.jobsType === 'enabling' && this.props.filter.jobsSubType.includes(0)}
                                                            name="radio-button-demo"
                                                            classes={{
                                                                root: classes.root,
                                                                checked: classes.checked,
                                                            }}
                                                        />
                                                        <ReactSVG src={rethinkIcon} svgStyle={{ width: 25, fill: '#A0A8B1' }}></ReactSVG>
                                                        <div className="filterby-label">Rethink the <b>Business</b> Model</div>
                                                    </div>

                                                    <div className='filter-item' onClick={(e) => this.onChangeFilter(2, 2, 'Design for the Future', 7)
                                                        }>
                                                        <Radio
                                                            className="filter-radio"
                                                            color="primary"
                                                            checked={this.props.filter.jobsType === 'enabling' && this.props.filter.jobsSubType.includes(2)}
                                                            name="radio-button-demo"
                                                            classes={{
                                                                root: classes.root,
                                                                checked: classes.checked,
                                                            }}
                                                        />
                                                        <ReactSVG src={designIcon} svgStyle={{ width: 25, fill: '#A0A8B1' }}></ReactSVG>
                                                        <div className="filterby-label"><b>Design</b> for the Future</div>
                                                    </div>

                                                    <div className='filter-item' onClick={() => this.onChangeFilter(2, 1, 'Team up to Create Joint Value', 8)}>
                                                        <Radio
                                                            className="filter-radio"
                                                            color="primary"
                                                            checked={this.props.filter.jobsType === 'enabling' && this.props.filter.jobsSubType.includes(1)}
                                                            name="radio-button-demo"
                                                            classes={{
                                                                root: classes.root,
                                                                checked: classes.checked,
                                                            }}
                                                        />
                                                        <ReactSVG src={collaborateIcon} svgStyle={{ width: 25, fill: '#A0A8B1' }}></ReactSVG>
                                                        <div className="filterby-label"><b>Team up</b> to Create Joint Value</div>
                                                    </div>


                                                    <div className='filter-item' onClick={() => this.onChangeFilter(2, 3, 'Incorporate Digital technology', 9)}>
                                                        <Radio
                                                            className="filter-radio"
                                                            color="primary"
                                                            checked={this.props.filter.jobsType === 'enabling' && this.props.filter.jobsSubType.includes(3)}
                                                            name="radio-button-demo"
                                                            classes={{
                                                                root: classes.root,
                                                                checked: classes.checked,
                                                            }}
                                                        />
                                                        <ReactSVG src={digitalIcon} svgStyle={{ width: 25, fill: '#A0A8B1' }}></ReactSVG>
                                                        <div className="filterby-label">Incorporate <b>Digital</b> technology</div>
                                                    </div>


                                                    <div className='filter-item group' onClick={() => this.onChangeFilter(3, -1, 'Indirectly Circular Jobs', 10)}>
                                                        <Radio
                                                            className="filter-radio"
                                                            color="primary"
                                                            checked={this.props.filter.jobsType === 'indirect'}
                                                            name="radio-button-demo"
                                                            classes={{
                                                                root: classes.root,
                                                                checked: classes.checked,
                                                            }}
                                                        />
                                                        <div className="filterby-label blue"><b>Indirectly Circular Jobs</b></div>
                                                    </div>

                                                </div>
                                                <div className="footer">
                                                    <div className="footer-icon">
                                                        <ReactSVG src={alertCircle} svgStyle={{ height: 17, width: 17, fill: '#A0A8B1', padding: '0 12px' }}></ReactSVG>
                                                    </div>
                                                    <div className="footer-text">
                                                        Filter circular jobs that are core/enabling/indirect or belong to a particular key element.
                                                        <span className="key-elements" onClick={() => this.openExplanation()}> What are the “Key Elements”?</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Card>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper >
        )
    }
}


export default withStyles(styles)(ListComponent);