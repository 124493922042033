import React, { Component } from 'react';
import classNames from 'classnames';
import 'dragscroll';
import { withStyles } from '@material-ui/core/styles';
import './Filter.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import arrowDown from '../../../assets/icons/arrowDown.png';
import Typography from '@material-ui/core/Typography';
import ListComponent from './List/List';


import { subscribeToSelectedGeoObject, subscribeToShowInfo } from '../../../services/selectedGeoObject'
import { subscribeToScopeData, selectScope } from '../../../services/scopeData';
import { isMobile } from "react-device-detect";

import ReactSVG from 'react-svg';

import regenerativeIcon from '../../../assets/icons/regenerative.svg';
import preserveIcon from '../../../assets/icons/preserve.svg';
import rethinkIcon from '../../../assets/icons/rethink.svg';
import wasteIcon from '../../../assets/icons/waste.svg';
import collaborateIcon from '../../../assets/icons/collaborate.svg';
import designIcon from '../../../assets/icons/design.svg';
import digitalIcon from '../../../assets/icons/digital.svg';

const styles = theme => ({
    root: {
        color: '#e8e8e8',
        '&$checked': {
            color: '#3876B7',
        },
    },
    checked: {},
    colorSwitchBase: {
        color: '#3876B7',
        '&$colorChecked': {
            color: '#3876B7',
            '& + $colorBar': {
                backgroundColor: '#9D9D9D'
            }
        }
    },
    colorBar: {},
    colorChecked: {},
    track: {
        backgroundColor: 'red'
    }
});

const filter = {
    1: {
        0: regenerativeIcon,
        1: preserveIcon,
        2: wasteIcon
    },
    2: {
        0: rethinkIcon,
        1: collaborateIcon,
        2: designIcon,
        3: digitalIcon
    }
}

class FilterComponent extends Component {
    _l = { country: 0, region: 1, province: 2, municipality: 3 }
    levels = ['Country', 'Region', 'Province', 'Municipality'];
    state = {
        type: 0,
        open: false,
        listId: null,
        filter: {
            title: '<b>All Circular Jobs</b>',
            jobsType: 'total',
            jobsSubType: [],
            dataRepresent: true
        },
        topFive: false,
        level: 'Country',
        scopeName: 'World',
        filterIcon: null
    }

    constructor(props) {
        super(props);
        subscribeToSelectedGeoObject(state => this.updateLevel(state));
        subscribeToScopeData(state => this.updateScope(state));
        this.onChangeFilter = this.onChangeFilter.bind(this);
        subscribeToShowInfo(state => {
            if(state.selected && state.selected.type) this.setState({level: 'Municipality'})
        })
    }

    onChangeFilter = (id, subId, title, e) => {
        if (subId !== -1) {
            if (this.state.filter.jobsSubType.length === 1 && { 0: 'total', 1: 'core', 2: 'enabling', 3: 'indirect' }[id] === this.state.filter.jobsType && this.state.filter.jobsSubType.includes(subId)) return;
            else {
                this.setState({
                    filterIcon: filter[id][subId],
                    filter: Object.assign(this.state.filter, {
                        jobsType: { 0: 'total', 1: 'core', 2: 'enabling', 3: 'indirect' }[id],
                        jobsSubType: [subId],
                        title: title
                    })
                });
            }
        } else {
            this.setState({ filterIcon: null })
            // toggle color by % or #
            if (id === -1) {
                this.setState({ filter: Object.assign(this.state.filter, { dataRepresent: !e.target.checked }) });
            }
            else if (id === 0) {
                this.setState({ filter: Object.assign(this.state.filter, { jobsType: 'total', jobsSubType: [], title: title }) });
            }
            else if (id === 1) {
                this.setState({ filter: Object.assign(this.state.filter, { jobsType: 'core', jobsSubType: [0, 1, 2], title: title }) });
            }
            else if (id === 2) {
                this.setState({ filter: Object.assign(this.state.filter, { jobsType: 'enabling', jobsSubType: [0, 1, 2, 3], title: title }) });
            }
            else if (id === 3) {
                this.setState({ filter: Object.assign(this.state.filter, { jobsType: 'indirect', jobsSubType: [], title: title }) });
            }
        }

        this.props.onFilterChanged(this.state.filter.jobsType, this.state.filter.dataRepresent, this.state.filter.jobsSubType);
    }

    handleToggle = listId => {
        this.setState(state => ({ open: state.listId !== listId && !state.open ? !state.open: state.open, listId: listId }), () => {this.getOffset();}); 
    };

    handleClose(e) {
        if(e.block || e.action === 'close' || e.type === 'click') {
            if (e.block === 'maplevel') {
                this.setState({ level: e.level })
            }
            if (e.block === 'filter') {
                this.onChangeFilter(e.id, e.subId, e.innerHtml, e.e)
            }
            if (e.block === 'scope') {
                selectScope(e.scope, true);
                this.setState({ scopeName: e.scope.name })
                return
            }
            this.setState({ open: false, openPosition: undefined, listId: null });
        }
    }

    updateLevel(state) {
        if (!state.updateZoomLevel) return;
        const level = state.showOnMap && state.selected ? (state.selected && state.selected.type) : (state.scoped && state.scoped.type) || (state.selected && state.selected.type);
        if (this._l[level.toLowerCase()] >= this._l[this.state.level.toLowerCase()]) {
            const adj = state.setAsScopeMap ? 1 : 0;
            this.setState({ level: this.levels[this._l[level.toLowerCase()] + adj] });
        }
    }

    updateScope(state) {
        if (state.scope.province.enable) {
            this.setState({ scopeName: state.scope.province.value, type: 3 })
        }
        else if (state.scope.region.enable) {
            this.setState({ scopeName: state.scope.region.value, type: 2 })
        }
        else if (state.scope.country.enable) {
            this.setState({ scopeName: state.scope.country.value, type: 1 })
        }
        else if (state.scope.world.enable) {
            this.setState({ scopeName: state.scope.world.value, type: 0 })
        }
    }

    onTopFiveClick() {
        this.setState((state) => {
            this.props.onTopFiveChanged(!state.topFive);
            return { topFive: !state.topFive }
        })
    }

    onZoomLevelSelected(level, force) {
        this.props.onZoomLevelSelected(level, force);
    }

    handleOpenExplanation(res) {
        this.props.handleOpenExplanation(res);
    }

    getOffset = () => {
        let el = document.querySelector('.is-active');
        let _x = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft;
            el = el.offsetParent;
        }
        const poper = document.querySelector(`.scope-tooltip`);
        if(poper) poper.style.left = _x + 'px';
    }

    render() {
        const { open, listId } = this.state;
        const { classes } = this.props;

        return (
            <div className={classNames('', { 'is-mobile': isMobile })}>
                <div className={classNames('filter-wrapper dragscroll no-print', { collapse: this.props.infoPaneCollapsed })}>
                    <div className="filter-block">
                        <div className="filter-block-header">
                            COLOR BY
                        </div>
                        <div className="filter-block-content colorby">
                            %<Switch 
                                disableRipple 
                                onChange={(e) => this.onChangeFilter(-1, -1, this.state.filter.title, e)} 
                                classes={{
                                    switchBase: classes.colorSwitchBase,
                                    checked: classes.colorChecked,
                                }}
                             color={"default"}
                        />#
                    </div>
                    </div>

                    <div className={classNames('filter-block jobs-filter', {'is-active': listId === 1})} onClick={() => this.handleToggle(1)}>
                        <div className="filter-block-header">
                            FILTER BY
                            <img className="arrow-down" src={arrowDown} alt={''} />
                        </div>
                        <div className="filter-block-content middle-block middle-block-filter">
                            {
                                this.state.filterIcon && <div className="filter-block-icon">
                                    <ReactSVG src={this.state.filterIcon} svgStyle={{ width: 30, fill: '#A0A8B1', marginRight: 5 }}></ReactSVG>
                                </div>
                            }
                            <div className="label-filter" dangerouslySetInnerHTML={{ __html: this.state.filter.title }}></div>
                        </div>
                    </div>

                    <div className={listId === 2 ? 'filter-block is-active' : 'filter-block'} onClick={() => this.handleToggle(2)}>
                        <div className="filter-block-header">
                            MAP LEVEL
                            <img className="arrow-down" src={arrowDown} alt={''} />
                        </div>
                        <div className="filter-block-content middle-block middle-block-maplevel">
                            <div className="label">{this.state.level}</div>
                        </div>
                    </div>

                    <div className={listId === 3 ? 'filter-block is-active' : 'filter-block'} onClick={() => this.handleToggle(3)}>
                        <div className="filter-block-header">
                            SCOPE
                            <img className="arrow-down" src={arrowDown} alt={''} />
                        </div>
                        <div className="filter-block-content middle-block">
                            <div className="label">{this.state.scopeName}</div>
                        </div>
                    </div>

                    <div className="filter-block top-5">
                        <div className="filter-block-header">
                            SHOW
                        </div>
                        <div className="filter-block-content">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onClick={() => this.onTopFiveClick()}
                                        classes={{
                                            root: classes.root,
                                            checked: classes.checked
                                        }}
                                    />
                                }
                                label={<Typography style={{ color: '#656565', fontWeight: 'bold', fontFamily: 'Montserrat', lineHeight: '25px', fontSize: '12px' }}>Top 5</Typography>} />
                        </div>
                    </div>
                </div>
                
                {
                    open && listId === 1 &&
                    (<ListComponent
                        block='filter'
                        handleOpenExplanation={this.handleOpenExplanation.bind(this)}
                        filter={this.state.filter}
                        open={this.state.open}
                        anchorEl={this.anchorEl}
                        handleClose={this.handleClose.bind(this)} />)
                }
                {
                    open && listId === 2 &&
                    (<ListComponent
                        block='maplevel'
                        level={this.state.level}
                        open={this.state.open}
                        anchorEl={this.anchorEl}
                        handleClose={this.handleClose.bind(this)}
                        onZoomLevelSelected={this.onZoomLevelSelected.bind(this)} />)
                }
                {
                    open && listId === 3 &&
                    (<ListComponent
                        type={this.state.type}
                        scopeName={this.state.scopeName}
                        scope={this.state.scope}
                        block='scope'
                        open={this.state.open}
                        anchorEl={this.anchorEl}
                        handleClose={this.handleClose.bind(this)} />)
                }
            </div>
        );
    }
}


export default withStyles(styles)(FilterComponent);