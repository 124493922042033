import React from "react";

function Methodology() {

    const divStyle = {
        height: '100%',
        position: 'absolute',
        width: '100%',
        overflow: 'hidden'
    }

    const linkStyle = {
        margin: '0 40px',
        lineHeight: '40px',
        textDecoration: 'none',
        color: 'blue'
    }

    const iframeStyle = {
        width: '100%',
        height: 'calc(100% - 40px)',
        overflowX: 'hidden'
    }

    return (
        <div style={divStyle}>
            <a href="/" style={linkStyle}>
                &lt;     Back to the monitor
            </a>
            <iframe style={iframeStyle} src="https://docs.google.com/document/d/e/2PACX-1vRw6uB-s334ldiakLn4atIzVHvsTTOE3VYCcNMJQ8XZjHOeOXZrU2MSEdL6lYpTW_GsLKX72Xg2q1GS/pub?embedded=true" />
        </div>
    );
}
export default Methodology;