import React, { Component } from 'react';
import './Map.css';
import L from 'leaflet'
import classNames from 'classnames';

import FilterComponent from './Filter/Filter'
import InfoPaneComponent from './InfoPane/InfoPane'
import LegendComponent from './Legend/Legend'
import { clickOnMap } from '../../services/selectedGeoObject'
import Rainbow from 'rainbowvis.js'
import _ from 'lodash'
import Button from '@material-ui/core/Button';
import { isMobile, MobileView, isBrowser } from 'react-device-detect';
import MobileInfoPopupComponent from './Mobile/InfoPopup/MobileInfoPopup'
import MobileInfoPaneComponent from './Mobile/InfoPane/MobileInfoPane'


import { subscribeToSelectedGeoObject, getState, setAsMapScope, setScope, showOnMap, showInfo } from '../../services/selectedGeoObject'
import { onEventSubscribe, onDispatchEvent } from '../../services/events'
import { selectScope } from '../../services/scopeData'
import { default as dataSvc } from "../../services/mapDataSvc";

import { withRouter } from "react-router-dom";

class MapComponent extends Component {
  showMapPinLevel = 8;
  layers = [];
  markerTopFiveGroup = [];
  markerPinGroup = [];
  map = null;
  currentDetailLevel;
  selectedFeatures = [];
  scopedFeatures = null;
  firstRainbow = new Rainbow();
  secondRainbow = new Rainbow();
  filter = { jobsType: 'total', dataRepresentPercent: true, topFive: false };

  _jobsType = {
    core: {
      0: 'Prioritize Regenerative Resources',
      1: 'Sustain and Preserve What is Already There',
      2: 'Use Waste as a Resource',
    },
    enabling: {
      0: 'Rethink the Business Model',
      1: 'Team up to Create Joint Value Model',
      2: 'Design for the Future',
      3: 'Incorporate Digital technology'
    }
  }


  constructor(props) {
    super(props);
    this.state = {
      printMod: false,
      max: 0,
      // infoPaneCollapsed: props.params.showInfoPane == 'true',
      //showInfoPane: props.params.showInfoPane == 'true',

      // show info panel if location is specified
      infoPaneCollapsed: props.match.params.locationId ? true : false,
      showInfoPane: props.match.params.locationId ? true : false,

      setScope: props.params.setScope == 'true',
      locationLevel: {country: 'country', region: 'region', province: 'province', municipality: 'municipality'}[props.match.params.locationLevel],
      mapLevel: props.match.params.locationId ? 'municipality' : 'country',
      locationId: props.match.params.locationId ? Number(props.match.params.locationId) : null,
      selected: { type: 'country', name: 'Belgium' },
      printScope: null,
      loading: true
    };
    this.firstRainbow.setSpectrum('#FFFFCD', '#93CFB8');
    this.secondRainbow.setSpectrum('#93CFB8', '#2E3790');

    subscribeToSelectedGeoObject(this.selectedChanged.bind(this));
    onEventSubscribe((state) => this.onPrint(state))
  }

  async componentDidMount() {
    if(isMobile && document.querySelector('#map')) {
      document.querySelector('#map').style.height = window.innerHeight - document.body.clientHeight + 'px';
    }
    this.map = L.map('map').setView([16.06860722083765, -16.54157366026398], 3);
    // L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiY2lyY2xldGFpciIsImEiOiJjam4wZGJ4NzMyY2p0M3BxeTdkMnU5enA1In0.QcqKQ5nRh5ri2lj3oSyfpg', {
    //   maxZoom: 12,
    //   minZoom: 6,
    //   id: 'mapbox.light'
    // }).addTo(this.map);

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
      tileSize: 512,
      maxZoom: 12,
      minZoom: 3,
      zoomOffset: -1,
      id: 'mapbox/light-v10',
      accessToken: 'pk.eyJ1IjoiY2lyY2xldGFpciIsImEiOiJjam4wZGJ4NzMyY2p0M3BxeTdkMnU5enA1In0.QcqKQ5nRh5ri2lj3oSyfpg'
    }).addTo(this.map);

    this.map.zoomControl.setPosition('bottomleft');

    if(isMobile) {
      this.map.zoomControl.remove();
    }

    this.map.on('zoomend', this.pinMarkerTrigger.bind(this));

    this.markerTopFiveGroup = L.layerGroup().addTo(this.map);
    this.markerPinGroup = L.layerGroup().addTo(this.map);

    this.updateDetailLevel(this.state.mapLevel || 'country');

/*     if (window.innerWidth < 1025) {
      await this.setState({ infoPaneCollapsed: false })
      this.onInfoPaneCollapse();
    } */
    // collapse info-pane as default

    this.onInfoPaneCollapse();
  }

  componentWillMount() {
    window.onafterprint = () => {
      onDispatchEvent('print', false);
    }
  }

  updateFilter(jobsType, dataRepresentPercent, jobsSubType) {
    this.filter = { jobsType: jobsType, dataRepresentPercent: dataRepresentPercent, jobsSubType, topFive: this.filter.topFive };

    onDispatchEvent('filter', this.filter);
    this.updateColorProgression(this.data, this.filter);
    this.pinMarkerTrigger();
  }

  updateColorProgression(data, filter) {
    const { dataRepresentPercent, jobsType, jobsSubType } = filter;

    if (this.scopedFeatures && this.currentDetailLevel === this.scopedFeatures.selected.type) return;

    const _f = {
      core: {
        0: 'PRR',
        1: 'PAEWAM',
        2: 'UWAAR',
      },
      enabling: {
        0: 'RTBM',
        1: 'CTCJV',
        2: 'DFTF',
        3: 'IDT'
      }
    };

    const mean = (data, key, percent) => {
      if (key === 'total' || key === 'core' || key === 'enabling' || key === 'indirect') {
        return ((1 / data.length) * _.sumBy(data, i => i[key][percent ? 'percent' : 'value']));
      } else {
        return ((1 / data.length) * _.sumBy(data, i => i[`${percent ? key + 'Percent' : key}`]));
      }
    }

    const standardDeviation = (data, key, percent, mean) => {
      if (key === 'total' || key === 'core' || key === 'enabling' || key === 'indirect') {
        return Math.sqrt((_.sumBy(data, i => Math.pow(i[key][percent ? 'percent' : 'value'] - mean, 2))) / (data.length - 1));
      } else {
        return Math.sqrt((_.sumBy(data, i => Math.pow(i[`${percent ? key + 'Percent' : key}`] - mean, 2))) / (data.length - 1));
      }
    }

    const getValue = (jobsType, valueType, feature) => {
      return ({
        total: {
            value: feature.totalCircularJobs,
            percent: _.round((feature.totalCircularJobs / feature.totalJobs) * 100, 1)
        },
        core: {
          value: feature.totalCore,
          percent: _.round((feature.totalCore / feature.totalJobs) * 100, 1),
        },
        enabling: {
          value: feature.totalEnabling,
          percent: _.round((feature.totalEnabling / feature.totalJobs) * 100, 1)
        },
        indirect: {
          value: feature.indirectJobs,
          percent: _.round((feature.indirectJobs / feature.totalJobs) * 100, 1)
        }
      }[jobsType][valueType]);

    }

    let _key = filter.jobsType;
    if (jobsSubType && jobsSubType.length === 1) {
      _key = _f[filter.jobsType][filter.jobsSubType[0]]
    }

    if (this.scopedFeatures && !(this.scopedFeatures.selected.name === 'Bruxelles' && this.currentDetailLevel === 'province')) {
      data.data = dataSvc.processData(
        data.data.data.filter(d => d[`${this.scopedFeatures.selected.type}Name`] === this.scopedFeatures.selected.name),
        this.currentDetailLevel);
    }

    let max;
    if (!jobsSubType || 'core enabling'.indexOf(jobsType) === -1 || jobsSubType.length === { core: 3, enabling: 4 }[jobsType]) {
      if(!data || !data.data || !Object.keys(data.data).length) return 0;
      max = dataRepresentPercent ? data.data[jobsType].maxPercent : data.data[jobsType].max;
    } else {
      max = {
        core: _.maxBy(jobsSubType.map(t => data.data.core.subCore[`max${_f.core[t]}${dataRepresentPercent ? 'Percent' : ''}`] || 0)),
        enabling: _.maxBy(jobsSubType.map(t => {
          return data.data.enabling.subEnabling[`max${_f.enabling[t]}${dataRepresentPercent ? 'Percent' : ''}`] || 0
        }))
      }[jobsType];
    }

    const _mean = mean(data.data.data, _key, filter.dataRepresentPercent);
    let frontier = _.round(standardDeviation(data.data.data, _key, filter.dataRepresentPercent, _mean) + _mean, dataRepresentPercent ? 2 : 0)

    if (max <= frontier) frontier = _.round(0.9 * max, dataRepresentPercent ? 2 : 0);

    this.setState({
      max: dataRepresentPercent ? `${max} %` : max,
      frontier: frontier ? `${frontier} %` : max,
    });

    if (frontier > 0) {
      this.firstRainbow.setNumberRange(0, frontier);
      this.secondRainbow.setNumberRange(frontier, max);
    }

    const layer = this.layers.find(x => x.id === this.currentDetailLevel).layer;
    layer.eachLayer(f => {
      let _color, _value = -1;

      if ((this.scopedFeatures && f.feature[`${this.scopedFeatures.selected.type}Name`] !== this.scopedFeatures.selected.name && !(this.scopedFeatures.selected.name === 'Bruxelles' && this.currentDetailLevel === 'province'))) {
        //_color = color().hex(f.options.fillColor).grayscale();
        _color = 'ffffff';
      } else {
        if (!jobsSubType || 'core enabling'.indexOf(jobsType) === -1 || jobsSubType.length === { core: 3, enabling: 4 }[jobsType]) {
          _value = dataRepresentPercent ? getValue(jobsType, 'percent', f.feature) : getValue(jobsType, 'value', f.feature);
          if (_value < frontier) {
            _color = this.firstRainbow.colorAt(_value);
          } else {
            _color = this.secondRainbow.colorAt(_value);
          }
        } else {
          _value = {
            core: _.sum(jobsSubType.map(t => f.feature[`${_f.core[t]}${dataRepresentPercent ? 'Percent' : ''}`] || 0)),
            enabling: _.sum(jobsSubType.map(t => f.feature[`${_f.enabling[t]}${dataRepresentPercent ? 'Percent' : ''}`] || 0))
          }[jobsType];

          if (_value < frontier || frontier === 0) {
            _color = this.firstRainbow.colorAt(_value);
          } else {
            _color = this.secondRainbow.colorAt(_value);
          }
        }
      }

      if (typeof _color === 'string') {
        _color = `#${_color}`;
      }

      if (this.selectedFeatures.indexOf(f) > -1) {
        f.setStyle(Object.assign({ weight: 5, fillOpacity: 1, color: `#FFD600`, fillColor: _color }));
      } else {
        f.setStyle(Object.assign({ weight: 1, fillOpacity: 1, color: '#ECECEC', fillColor: _color, opacity: .5 }));
      }
      f.feature._value = _value;
      f.feature._color = _color;
    });

    if (this.filter.topFive) this.topFive(this.filter.topFive, true);
  }

  async selectedChanged(selectedState, fromDetailLevel) {
    this.setState({printScope: selectedState.scoped});

    // object from query string params
    if(this.state.locationLevel && this.state.locationId) {
      // noinspection EqualityComparisonWithCoercionJS
      const _data = (await dataSvc.getMapData(this.state.locationLevel)).data.data.find(d => d.id == this.state.locationId);
      if(_data) {
        selectedState = {
          selected: {
            id: this.state.locationId,
            name: _data[`${this.state.locationLevel}Name`],
            type: this.state.locationLevel,
          },
          showInfo: false,
          showOnMap: true,
          updateZoomLevel: false,
          highlightOnMap: true,
          setAsScopeMap: this.state.setScope,
          scoped: this.state.setScope && this.state.locationLevel !== 'municipality' ? {
            id: this.state.locationId,
            name: _data[`${this.state.locationLevel}Name`],
            type: this.state.locationLevel,
          } : null
        }

        this.setState({
          printScope:
            this.state.setScope && this.state.locationLevel !== 'municipality' ? {
              id: this.state.locationId,
              name: _data[`${this.state.locationLevel}Name`],
              type: this.state.locationLevel,
            } : null
        })

        if(this.state.setScope && this.state.locationLevel !== 'municipality') {
          setScope({
            id: this.state.locationId,
            name: _data[`${this.state.locationLevel}Name`],
            type: this.state.locationLevel,
          });
        }
        if(!isMobile || this.state.showInfoPane) showInfo(selectedState);
        this.setState({locationLevel: null, locationId: null, setScope: null});
      }

    }

    if (!selectedState.selected && !selectedState.scoped) return;
    let _layer;
    let selectedFeaturesToHighlight = [];
    if (selectedState.setAsScopeMap && selectedState.scoped && selectedState.scoped.type === 'world') {
      if (selectedState.showOnMap) { 
        _layer = (this.layers.find(x => x.id === selectedState.scoped.type) || { layer: null }).layer;
        _layer && _layer.eachLayer(f => {
          selectedFeaturesToHighlight.push(f);
        });
        this.addBorderAnimate(selectedFeaturesToHighlight);
        this.selectedFeatures = selectedFeaturesToHighlight;
      }
      if (this.scopedFeatures && this.scopedFeatures.features)
        this.removeBorderAnimate(this.scopedFeatures.features, []);
      this.scopedFeatures = null;

      this.data = await dataSvc.getMapData(this.currentDetailLevel);
      this.updateColorProgression(this.data, this.filter);
      return;
    }
    let scopedFeatureToHighlight = null;
    const _w = { country: 0, region: 1, province: 2, municipality: 3 };
    const __w = { 0: 'country', 1: 'region', 2: 'province', 3: 'municipality' };

    if (selectedState.selected && selectedState.showInfo) {
      this.setState({ selected: { type: selectedState.selected.type, name: selectedState.selected.name } })
      if(this.state.infoPaneCollapsed) {
        this.onInfoPaneCollapse();
      }
    }
    if (selectedState.highlightOnMap) {
      if (selectedState.selected) {
        _layer = (this.layers.find(x => x.id === selectedState.selected.type) || { layer: null }).layer;
        const selectedType = selectedState.selected.originalType || selectedState.selected.type;
        if(this.state.mapLevel !== selectedState.selected.type) {
          //if selectedState.selected from query strings
          _layer && _layer.eachLayer(f => {
            if(f.feature[`${selectedType}Id`] == selectedState.selected.id) {
              selectedFeaturesToHighlight.push(f);
            }
          });
        } else {
          _layer && _layer.eachLayer(f => {
            if(f.feature[`${selectedType}Id`] == selectedState.selected.id && f.feature[`${selectedType}Name`] == selectedState.selected.name) {
              selectedFeaturesToHighlight.push(f);
            }
          });
        }

      }
      if (selectedState.scoped && selectedState.scoped.type !== 'world') {
        if (_w[selectedState.scoped.type] >= _w[this.currentDetailLevel]) {
          if (fromDetailLevel) {
            if (selectedState.scoped.type === 'country') {
              setScope();
              selectScope();
            } else {
              //up level scope
              let currentList = {
                'country': await dataSvc.getCountriesList(),
                'region': await dataSvc.getRegionsList(),
                'province': await dataSvc.getProvincesList()
              }[selectedState.scoped.type];
              
              let currentScope = currentList.find(e => e.id === selectedState.scoped.id);
              let nextScope = {
                id: currentScope[`${__w[_w[selectedState.scoped.type] - 1]}Id`],
                name: currentScope[`${__w[_w[selectedState.scoped.type] - 1]}Name`],
                type: __w[_w[selectedState.scoped.type] - 1]
              }

              setAsMapScope(nextScope, { showInfo: false, updateScope: true });
            }
          } else {
            this.updateDetailLevel(__w[_w[selectedState.scoped.type] + 1]);
          }
          return;
        }

        if (selectedState.setAsScopeMap && selectedState.selected && selectedState.scoped && this.isOutOfScope(this.data.data.data, selectedState.selected, selectedState.scoped)) {
          //out of scope(change scope)
          showOnMap(selectedState.scoped, { highlightOnMap: false, showOnMap: false });
          return;
        } else if (this.scopedFeatures && selectedState.selected && !this.data.data.data.some(d => d[`${selectedState.selected.type}Name`] === selectedState.selected.name)) {
          //out of scope
          const currentSelectedScope = this.scopedFeatures.selected;
          let selectedScopeData = await dataSvc.getDataForGeoObject(selectedState.selected);
          const nextScopeData = await dataSvc.getDataForGeoObject({
            type: currentSelectedScope.type,
            id: selectedScopeData[`${currentSelectedScope.type}Id`]
          });

          const nextScope = {
            type: currentSelectedScope.type,
            name: nextScopeData[`${currentSelectedScope.type}Name`],
            id: nextScopeData[`${currentSelectedScope.type}Id`]
          }

          let _layer = (this.layers.find(x => x.id === this.currentDetailLevel) || { layer: null }).layer;
          _layer.eachLayer(f => {
            if (f.feature[`${currentSelectedScope.type}Id`] === selectedScopeData[`${currentSelectedScope.type}Id`]) scopedFeatureToHighlight = f;
          });
          this.scopedFeatures = { features: [scopedFeatureToHighlight], selected: nextScope };
          this.data = await dataSvc.getMapData(this.currentDetailLevel);

          selectScope(nextScope, true);

          _layer = (this.layers.find(x => x.id === nextScope.type) || { layer: null }).layer;
          _layer.eachLayer(f => {
            if (f.feature.id === nextScope.id) scopedFeatureToHighlight = f;
          });
        } else {
          
          _layer = (this.layers.find(x => x.id === selectedState.scoped.type) || { layer: null }).layer;
          _layer && _layer.eachLayer(f => {
            if (f.feature.id === selectedState.scoped.id) {
              scopedFeatureToHighlight = f;
            }
          });
        }
      }
    }

    if (selectedState.setAsScopeMap || selectedState.updateZoomLevel) {
      if (selectedState.scoped && selectedState.scoped.type !== 'world') {

        _layer = (this.layers.find(x => x.id === selectedState.scoped.type) || { layer: null }).layer;
        _layer && _layer.eachLayer(f => {
          if (f.feature.id === selectedState.scoped.id) {
            scopedFeatureToHighlight = f;
          }
        });

        this.scopedFeatures = { features: [scopedFeatureToHighlight], selected: selectedState.scoped };
        this.data = await dataSvc.getMapData(this.currentDetailLevel);
        selectScope(selectedState.scoped, false);
        this.updateColorProgression(this.data, this.filter);
      }
    }

    if (selectedState.showOnMap) {
      if (!fromDetailLevel && _w[selectedState.selected.type] > _w[this.currentDetailLevel]) {
        this.updateDetailLevel(__w[_w[selectedState.selected.type]]);
        return;
      }

      selectedFeaturesToHighlight[0] && this.map.fitBounds(selectedFeaturesToHighlight[0].getBounds(), { maxZoom: 10 });
    }

    let highlightFeatures = [...selectedFeaturesToHighlight, scopedFeatureToHighlight].filter(f => !!f);

    if (scopedFeatureToHighlight) {
      let _scopedFeatureToHighlight = [scopedFeatureToHighlight]
      if (this.scopedFeatures.selected.type === 'region' && this.scopedFeatures.selected.name === 'Vlaanderen') {
        //add Brussel/Bruxelles border
        _layer && _layer.eachLayer(f => {
          if (f.feature.regionId === 1) {
            _scopedFeatureToHighlight.push(f);
            this.scopedFeatures.features.push(f);
            this.selectedFeatures = this.selectedFeatures.filter(f => f.feature.regionId !== 1);
          }
        });
      } else if (this.scopedFeatures.selected.type === 'region' && this.scopedFeatures.selected.name !== 'Vlaanderen') {
        //remove Brussel/Bruxelles border
        _layer && _layer.eachLayer(f => {
          if (f.feature.regionId === 1) {
            this.selectedFeatures.push(f);
          }
        });
      }
      //this.addBorderAnimate(_scopedFeatureToHighlight, true, '#ECECEC');
    }

    if (selectedFeaturesToHighlight) this.addBorderAnimate(selectedFeaturesToHighlight, true);
    this.removeBorderAnimate(this.selectedFeatures, highlightFeatures);

    this.selectedFeatures = highlightFeatures;
  }

  addBorderAnimate(features, ignore, color) {
    if ((!ignore && this.animationInProgress) || !features || !features.length) return;
    this.animationInProgress = true;

    for (let feature of features) {
      const fps = 30;
      let opacity = 0;
      feature.setStyle({ color: color || '#FFD600', opacity, stroke: true, weight: 5 });

      let timer = setInterval(() => {
        if (opacity >= 1) {
          this.animationInProgress = false;
          clearInterval(timer);
        }
        else {
          opacity = _.round(opacity + 0.05, 2);
          feature.setStyle({ opacity: opacity })
        }
      }, 1000 / fps);
    }
  }

  removeBorderAnimate(features, featuresToHighlight, timeout) {
    for (let feature of features) {
      if (featuresToHighlight.some(f => f.feature === feature.feature)) continue;
      setTimeout(() => {
        const fps = 30;
        let opacity = 1;
        let timer = setInterval(() => {
          if (opacity <= 0) {
            feature && feature.setStyle({ stroke: false })
            clearInterval(timer);
          }
          else {
            opacity = _.round(opacity - 0.05, 2);
            feature && feature.setStyle({ opacity: opacity })
          }
        }, 1000 / fps);
      }, timeout * 1000);
    }
  }

  async updateDetailLevel(level, force) {
    this.setState({ loading: true });
    if (this.currentDetailLevel === level) return;

    this.scopedFeatures = null;

    this.currentDetailLevel = level;
    this.setState({ currentDetailLevel: level })
    this.layers.forEach(l => {
      this.map.removeLayer(l.layer)
    });

    this.layers = [];

    this.data = await dataSvc.getMapData(this.currentDetailLevel);
    await this.initLayers(this.data);

    this.updateColorProgression(this.data, this.filter);

    this.pinMarkerTrigger();
    this.selectedChanged(Object.assign(getState(), { updateZoomLevel: true }), force);
    this.setState({ loading: false });
  }

  mapMouseover(event) {
    let highlight = true;
    event.target.openPopup(event.latlng);
/*     this.addBorderAnimate([event.layer], true);
    highlight = false; */

    if (!event.layer.feature) {
      event.layer.feature = Object.values(event.sourceTarget._eventParents)[0].feature;
    }

    if (highlight && !this.selectedFeatures.some(f => f.feature.id === event.layer.feature.id)) {
      event.layer.setStyle({
        color: 'rgba(189, 189, 189, .6)',
        weight: 4,
        stroke: true,
        opacity: 1
      });
    }

    let _jobsSubType = this.filter.jobsSubType && this.filter.jobsSubType.length === 1 ? this.filter.jobsSubType[0] : undefined;
    const popupContent = dataSvc
      .getFeaturePopup(this.currentDetailLevel, this.filter.jobsType, this.filter.dataRepresentPercent, event.layer.feature, _jobsSubType);

    event.target.setPopupContent(popupContent);
  }

  mapMouseout(event) {
    event.target.closePopup()

    if (!event.layer.feature) {
      console.warn('no feature mapMouseout');
      return;
    }

    if (!this.selectedFeatures.some(f => f.feature.id === event.layer.feature.id)) event.layer.setStyle({ weight: 1, color: '#ECECEC', opacity: .5 });
  }

  async initLayers(data) {
    let mainLayer = L.geoJSON(data.geoJSON, {
      pane: 'tilePane',
      renderer: L.svg(),
      onEachFeature: _.noop,
      style: (_) => {
        return { color: `rgba(0, 0, 0, 0)`, weight: 1, fillOpacity: 0 }
      }
    });

    mainLayer
      .on('click', (target) => {
        clickOnMap(dataSvc.getSelectedItem(target.layer.feature));
      })
      .on('mouseover', this.mapMouseover.bind(this))
      .on('mouseout', this.mapMouseout.bind(this))
      .addTo(this.map);

      if(!isMobile) {
        mainLayer.bindPopup((_) => ``);
      }

    this.layers.push({ id: this.currentDetailLevel, layer: mainLayer });

    let countryLayer = L.geoJSON((await dataSvc.getMapData('country')).geoJSON, {
      pane: 'tilePane',
      renderer: L.svg(),
      interactive: false,
      style: (_) => {
        return { color: `rgba(0, 0, 0, 0)`, weight: 1, fillOpacity: 0 }
      }
    }
    ).addTo(this.map);

    this.layers.push({ id: 'country', layer: countryLayer });

    if (this.currentDetailLevel === 'municipality' || this.currentDetailLevel === 'province') {
      let regionsLayer = L.geoJSON((await dataSvc.getMapData('region')).geoJSON, {
        pane: 'tilePane',
        renderer: L.svg(),
        interactive: false,
        style: (_) => {
          return { color: `rgba(0, 0, 0, 0)`, weight: 1, fillOpacity: 0 }
        }
      }
      ).addTo(this.map);

      this.layers.push({ id: 'region', layer: regionsLayer });
    }

    if (this.currentDetailLevel === 'municipality') {
      let provincesLayer = L.geoJSON((await dataSvc.getMapData('province')).geoJSON, {
        pane: 'tilePane',
        renderer: L.svg(),
        interactive: false,
        style: (_) => {
          return { color: `rgba(0, 0, 0, 0)`, weight: 1, fillOpacity: 0 }
        }
      }).addTo(this.map);

      this.layers.push({ id: 'province', layer: provincesLayer });
    }
  }

  topFive(value, force) {
    this.filter.topFive = value;
    if (!value) return this.markerTopFiveGroup.clearLayers();
    else if (force) this.markerTopFiveGroup.clearLayers();
    
    const layer = this.layers.find(x => x.id === this.currentDetailLevel).layer;
    const _l = _.slice(_.sortBy(layer._layers, (l) => -l.feature._value), 0, 5).filter(l => l.feature._value > 0).map(l => ({id: l.feature[`${l.feature.featureType || l.feature.detailLevel}Id`], type: l.feature.featureType || l.feature.detailLevel}));
    layer.eachLayer(f => {
      let index = _l.findIndex(__l => __l.id === f.feature[`${__l.type}Id`] && __l.type === (f.feature.featureType || f.feature.detailLevel));
      if (index > -1) {
        const icon = L.divIcon({ html: `<div class="top-five-marker"><div class="text">${index + 1}</div></div>` })
        let LatLng = f.getBounds().getCenter();
        if (f.feature.id === 2) {
          LatLng.lng -= 0.02;
        }
        if (f.feature.id === 3) {
          LatLng.lng += 0.01;
        }
        let marker = L.marker([LatLng.lat, LatLng.lng], {
          interactive: true,
          icon: icon,
          f: f,
          l: layer
        });

        this.bindMarkerPopup(marker, f.feature);
        marker.addTo(this.markerTopFiveGroup);
      }
    });
  }

  pinMarker(value, f, layer) {
    if(!value) {
      return this.markerPinGroup.clearLayers();
    }

    const { dataRepresentPercent, jobsType, jobsSubType } = this.filter;

    const LatLng = f.getBounds().getCenter();
    const _color = f.feature._color;

    const icon = L.divIcon({ html: `<div class="pin-marker"><div class="marker-color" style="background: ${_color}"></div></div>` });
    let marker = L.marker([LatLng.lat, LatLng.lng], {
      interactive: true,
      icon: icon,
      f: f,
      l: layer
    });

    marker.addTo(this.markerPinGroup);
  }

  pinMarkerTrigger() {
    this.markerPinGroup.clearLayers();

    if(this.map.getZoom() < this.showMapPinLevel) {
      const layer = this.layers.find(_l => _l.id === this.currentDetailLevel) && this.layers.find(_l => _l.id === this.currentDetailLevel).layer;

      if(layer) {
        layer.eachLayer(f => {
          if(f.feature.properties.pin) {
            const LatLng = f.getBounds().getCenter();
            const _color = f.feature._color;
    
            const icon = L.divIcon({ html: `<div class="pin-marker"><div class="marker-color" style="background: ${_color}"></div></div>` });
            let marker = L.marker([LatLng.lat, LatLng.lng], {
              interactive: true,
              icon: icon,
              f: f,
              l: layer
            });
    
            marker.on('click', (target) => clickOnMap(dataSvc.getSelectedItem(f.feature)));
            this.bindMarkerPopup(marker, f.feature);
            marker.addTo(this.markerPinGroup);
          }
        })
      }
    }
  }

  isOutOfScope(data, selected, scoped) {
    let _l = data.filter(d => d[`${selected.type}Name`] === selected.name);
    _l = _l ? _l[0] : null;
    if (!_l) return false;

    if (_l[`${scoped.type}Name`] !== scoped.name) return true;
    else return false;
  }

  bindMarkerPopup(marker, feature){
    const _jobsSubType = this.filter.jobsSubType && this.filter.jobsSubType.length === 1 ? this.filter.jobsSubType[0] : undefined;

    marker.bindPopup(dataSvc.getFeaturePopup(this.currentDetailLevel, this.filter.jobsType, this.filter.dataRepresentPercent, feature, _jobsSubType));

    marker.on('mouseover', (f) => {
      f.target.openPopup(f.latlng);
    })

    marker.on('mouseout', (f) => {
      f.target.closePopup();
    })
  }

  onInfoPaneCollapse() {
    let width = 450;
    if (window.innerWidth < 440) {
      width = 325;
    }
    const legendEl = document.querySelector('.legend-wrapper');
    const infoPaneEl = document.querySelector('.info-pane-outer-wrapper');

    if(infoPaneEl) infoPaneEl.style.right = this.state.infoPaneCollapsed ? `0px` : `-${width}px`;

    if(legendEl && isMobile) {
      legendEl.style.opacity = this.state.infoPaneCollapsed ? `0` : `1`;
    }

    window.dispatchEvent(new Event('resize'));

    this.setState(state => { return { infoPaneCollapsed: !state.infoPaneCollapsed } })
  }

  onPrint = (state) => {
    if(state.print && this.selectedFeatures && this.selectedFeatures.length) {
      // to centre selected areas
      this.map.fitBounds(this.selectedFeatures.map(f => f.getBounds()), {paddingTopLeft: [-1200, -550]});
    }

    this.setState({ printMod: state.print });
  }

  handleOpenExplanation = () => {
    // 3 for key concepts
    this.props.handleOpenExplanation({ open: true, tab: "3" });
  }

  handleMobilePopupClose = () => {
    this.onInfoPaneCollapse();
  }

  handleOpenShareLink = () => {
    this.props.handleOpenShareLink();
  }

  render() {
    return (
      <div className={classNames('map-wrapper', { 'print': this.state.printMod })}>
        {this.state.loading ?
          <div className="loading">
            <div className="spinner">
              <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              <p className="spinner-text">Wait please, map is loading..</p>
            </div>
          </div> : null}
        <div className="print-header">
          <p className="subtitle">CIRCULAR JOBS MONITOR</p>
        </div>
        <div className={classNames('map-components-wrapper', { print: this.state.printMod, 'infopane': !isMobile && !this.state.infoPaneCollapsed})}>
          {!this.state.printMod && (<FilterComponent handleOpenExplanation={this.handleOpenExplanation} onZoomLevelSelected={this.updateDetailLevel.bind(this)} onFilterChanged={this.updateFilter.bind(this)} onTopFiveChanged={this.topFive.bind(this)} infoPaneCollapsed={this.state.infoPaneCollapsed} />)}
          {/* {!this.state.printMod && (<ZoomLevelComponent onZoomLevelSelected={this.updateDetailLevel.bind(this)}/>)} */}
          <LegendComponent max={this.state.max} frontier={this.state.frontier} unit={this.filter.dataRepresentPercent ? ' %' : ' jobs'} />
          <div id="map"></div>
          <MobileView>
            <MobileInfoPopupComponent handleMobilePopupClose={this.handleMobilePopupClose} handleOpenInfoPane={this.handleOpenInfoPane} filter={this.filter}/>
          </MobileView>
        </div>
        <p className="subtitle-print">{this.filter.dataRepresentPercent ? 'Percent' : 'Number'} of {this.filter.jobsSubType && this.filter.jobsSubType.length === 1 && `“${this._jobsType[this.filter.jobsType][this.filter.jobsSubType[0]]}”`} {this.filter.jobsType} circular jobs in {{ municipality: 'municipalities', province: 'provinces', region: 'regions', country: 'countries' }[(this.currentDetailLevel) || 'country']}&nbsp;
          {this.state.printScope && this.state.printScope.type !== 'world' ? `of ${this.state.printScope.type} ${this.state.printScope.name}` : '' }
        </p>
        {isBrowser && (<div className={classNames('info-pane-outer-wrapper', { 'print': this.state.printMod })}>
            <div onClick={this.onInfoPaneCollapse.bind(this)} className={classNames('collapse-button', { 'open': this.state.infoPaneCollapsed })}></div>
            <InfoPaneComponent currentDetailLevel={this.state.currentDetailLevel} handleOpenShareLink={this.handleOpenShareLink} />
          </div>)}
        <MobileView>
          <MobileInfoPaneComponent currentDetailLevel={this.state.currentDetailLevel} />
        </MobileView>

        {this.state.printMod && (<div className="print-content-wrapper">
          <div className="print-title">Adjust the map for printing</div>
          <div className="button-row">
            <Button variant="contained" color="primary" className="print-button" onClick={() => window.print()}>Print</Button>
            <Button variant="text" color="default" onClick={() => onDispatchEvent('print', false)}>Cancel</Button>
          </div>
        </div>)}
      </div>
    );
  }
}

export default withRouter(MapComponent);