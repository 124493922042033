import {default as dataSvc} from './mapDataSvc';
import {setAsMapScope} from './selectedGeoObject';

let state = {
    scope: {
        world: {
            id: 0,
            enable: true,
            value: 'World'
        },
        country: {
            id: 1,
            enable: false
        },
        region: {
            id: 2,
            enable: false
        },
        province: {
            id: 3,
            enable: false
        }
    }
};


(async () => {
    state.countriesList = await dataSvc.getCountriesList();
    state.regionsList = await dataSvc.getRegionsList();
    state.provincesList = await dataSvc.getProvincesList();
})()

const listeners = [];

export {
    getScopeDataState,
    getSelectScopes,
    selectScope,
    subscribeToScopeData,
    currentScope
};

function getScopeDataState() {
    return state;
}

function getSelectScopes(_type) {
    return {
        0: [],
        1: (state.countriesList || []).map(country => Object.assign(country, {type: 'country'})),
        2: (state.regionsList || []).filter(region => state.scope.country.enable ? region.countryId === state.scope.country.countryId : true).map(region => Object.assign(region, {type: 'region'})),
        3: (state.provincesList || []).filter(province => state.scope.region.enable ? province.regionId === state.scope.region.regionId : true).map(province => Object.assign(province, {type: 'province'}))
    }[_type || currentScope()]
}

function selectScope(scope, updateMapScope) {
    if(!scope) {
        for(let s in state.scope) {
            if(s !== 'world') state.scope[s].enable = false;
        }

    } else {
        const _weight = {
            world: 0,
            country: 1,
            region: 2,
            province: 3
        }
        const _scopeState = {
            0: state.scope.world,
            1: state.scope.country,
            2: state.scope.region,
            3: state.scope.province
        }
    
        const _currentScope = currentScope();
         if(_weight[scope.type] < 2 || _weight[scope.type] < 2 - _currentScope) {
            for(let s in state.scope) {
                if(_weight[scope.type] < _weight[s]) {
                    state.scope[s].enable = false;
                }
            }

            if(scope.type === 'country') {
                _scopeState[_weight[scope.type]].enable = true;
                _scopeState[_weight[scope.type]].value = scope.name;
                _scopeState[_weight[scope.type]].id = scope.id;
                _scopeState[_weight[scope.type]][`${scope.type}Id`] = scope.id;
            } else if(scope.type === 'region') {
                _scopeState[_weight[scope.type]].value = scope.name;
                _scopeState[_weight[scope.type]].id = scope.id;
                _scopeState[_weight[scope.type]][`${scope.type}Id`] = scope.id;
            }
    
        } else if(!_currentScope || _weight[scope.type] > 2 - _currentScope) {
            //only province now
            const province = state.provincesList.find(p => p.id === scope.id);

            if(scope.type === 'province') {
                _scopeState[1].value = province.countryName;
                _scopeState[1].enable = true;
                _scopeState[1].id = province.countryId;
                _scopeState[1].countryId = province.countryId;
            }


            _scopeState[2].value = province.regionName;
            _scopeState[2].enable = true;
            _scopeState[2].id = province.regionId;
            _scopeState[2].regionId = province.regionId;
    
            _scopeState[_weight[scope.type]].value = scope.name;
            _scopeState[_weight[scope.type]].enable = true;
            _scopeState[_weight[scope.type]].id = scope.id;
            _scopeState[_weight[scope.type]].regionId = scope.id;

            if(_weight[scope.type] === 2) _scopeState[3].enable = false;
    
        } else {
            _scopeState[_currentScope].value = scope.name;
            _scopeState[_currentScope].enable = true;
            _scopeState[_currentScope].id = scope.id;
            _scopeState[_currentScope][`${scope.type}Id`] = scope.id;
        }

        if(updateMapScope) setAsMapScope({id: scope.id, name: scope.name, type: scope.type}, {updateScope: true});
    }
    listeners.forEach(f => f(state));
}

function currentScope() {
    let _currentScope = 0;
    let index = 0;
    for(let s of ['world', 'country', 'region', 'province']) {
        let item = state.scope[s];
        if(!item.enable){
            _currentScope = index - 1;
            break;
        }
        index++;
    };
    return _currentScope;
}


function subscribeToScopeData(func) {
    listeners.push(func);
}