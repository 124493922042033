let state = {
  selected: null,
  scoped: null,
  highlightOnMap: false,
  showOnMap: false
};

const actions = {
  selectFromSearch: 'SELECT_FROM_SEARCH',
  clickOnMap: 'CLICK_ON_MAP',
  showOnMap: 'SHOW_ON_MAP',
  setAsMapScope: 'SET_AS_MAP_SCOPE',
};

const listeners = [];
const showInfoListeners = [];

export {
  getState,
  selectFromSearch,
  showOnMap,
  clickOnMap,
  subscribeToSelectedGeoObject,
  setAsMapScope,
  setScope,
  subscribeToShowInfo,
  showInfo
};

function getState() {
  return state;
}

function clickOnMap(selected) {
  state = Object.assign({selected: state.selected, scoped: state.scoped},selectedGeoObject(actions.clickOnMap, selected));
  listeners.forEach(f => f(state));
}

function showOnMap(selected, options) {
  state = Object.assign({selected: state.selected, scoped: state.scoped},selectedGeoObject(actions.showOnMap, selected, options));
  listeners.forEach(f => f(state));
}

function selectFromSearch(selected) {
  state = Object.assign({selected: state.selected, scoped: state.scoped},selectedGeoObject(actions.selectFromSearch, selected));
  listeners.forEach(f => f(state));
}

function setAsMapScope(selected, options) {
  state = Object.assign({selected: state.selected, scoped: state.scoped}, selectedGeoObject(actions.setAsMapScope, selected, options));
  listeners.forEach(f => f(state));
}

function setScope(scope) {
  state.scoped = scope;
}

function subscribeToSelectedGeoObject(func) {
  listeners.push(func);
}

function subscribeToShowInfo(func) {
  showInfoListeners.push(func);
}

function showInfo(selected) {
  showInfoListeners.forEach(f => f(selected));
}

function selectedGeoObject(action, selected, options) {
  options = options || {};
  switch (action) {
    case actions.selectFromSearch:
      return Object.assign({
        selected,
        highlightOnMap: true,
        showOnMap: true,
        showInfo: true,
        updateZoomLevel: true
      }, options);
    case actions.clickOnMap:
      return Object.assign({
        selected,
        highlightOnMap: true,
        showOnMap: false,
        showInfo: true,
        updateZoomLevel: false
      }, options);
    case actions.showOnMap:
      return Object.assign({
        selected,
        scoped: state.scoped,
        highlightOnMap: true,
        showOnMap: true,
        showInfo: true,
        updateZoomLevel: true
      }, options);
    case actions.setAsMapScope:
      return Object.assign({
        selected: state.selected,
        scoped: selected,
        highlightOnMap: true,
        showOnMap: false,
        setAsScopeMap: true,
        updateZoomLevel: true
      }, options);
    default:
      return state;
  }
}