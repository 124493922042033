import React, { Component } from 'react'
import './RadarChart.css'
import { Radar } from 'react-chartjs-2'
import ReactSVG from 'react-svg'
import _ from 'lodash'

import regenerativeIcon from '../../../../assets/icons/regenerative.svg';
import preserveIcon from '../../../../assets/icons/preserve.svg';
import wasteIcon from '../../../../assets/icons/waste.svg';
import rethinkIcon from '../../../../assets/icons/rethink.svg';

import collaborateIcon from '../../../../assets/icons/collaborate..svg';
import designIcon from '../../../../assets/icons/design.svg';
import digitalIcon from '../../../../assets/icons/digital.svg';

import { default as dataSvc } from "../../../../services/mapDataSvc";
import { subscribeToSelectedGeoObject } from '../../../../services/selectedGeoObject'
import { subscribeToChartData } from '../../../../services/chartData';

import selected from '../../../../assets/selected.png';
import compareto from '../../../../assets/compareto.png';

let indexes = [];

let datasets = [];
let _max = 0;
const chartData = {
    labels: ['', '', '', '', '', '', ''],
    datasets: [
        {
            label: 'Selected',
            backgroundColor: 'transparent',
            borderColor: '#3D6E6B',
            pointBackgroundColor: '#3D6E6B',
            pointBorderColor: '#3D6E6B',
            borderWidth: 2,
            pointRadius: 2,
            pointHoverRadius: 2,
            data: [0, 0, 0, 0, 0, 0, 0]
        },
        {
            label: 'Compare',
            backgroundColor: 'rgba(130,221,190, .8)',
            borderColor: 'rgba(130,221,190, .8)',
            pointBackgroundColor: 'rgba(130,221,190, .8)',
            pointBorderColor: 'rgba(130,221,190, .8)',
            pointRadius: 3,
            pointHoverRadius: 3,
            data: [0, 0, 0, 0, 0, 0, 0]
        }
    ],
    options: {
        tooltips: {
            mode: 'indexMode1',
            enabled: false,
            intersect: false,
            callbacks: {
                label: (tooltipItem, data) => {
                    return {
                        index: tooltipItem.index,
                        dataSetIndex: tooltipItem.datasetIndex,
                        image: tooltipItem.datasetIndex === 1 ? selected : compareto,
                        label: `${datasets[tooltipItem.datasetIndex] ? datasets[tooltipItem.datasetIndex][tooltipItem.index] : '0'} %`,
                        color: tooltipItem.datasetIndex === 1 ? '#65B19B' : '#306E6A',
                    };
                }
            },
            custom: function (tooltip, changed) {
                indexes.forEach(i => {
                    let tooltip = document.getElementById(`index${i}`);
                    tooltip.style.opacity = 0;
                    let icon = document.getElementById(`icon-index${i}`);
                    icon.style.transform = 'scale(1)';
                })
                indexes = [];
                // Tooltip Element
                let tooltipEl = document.getElementById('chartjs-tooltip');
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<table></table>';
                    this._chart.canvas.parentNode.appendChild(tooltipEl);
                }
                // Hide if no tooltip
                if (tooltip.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }
                function getBody(bodyItem) {
                    indexes = _.map(bodyItem, 'index');
                    // Tooltip Element
                    bodyItem.forEach(i => {
                        let tooltip = document.getElementById(`index${i.index}`);
                        tooltip.style.opacity = 1;
                        let icon = document.getElementById(`icon-index${i.index}`);
                        icon.style.transform = 'scale(1.25)';
                    });
                    return _.uniqBy(bodyItem, 'dataSetIndex');
                }
                // Set Text
                if (tooltip.body) {
                    let tooltipsData = tooltip.body.map(b => b.lines[0]).sort((a, b) => b.dataSetIndex - a.dataSetIndex);
                    const bodyLines = getBody(tooltipsData);
                    let innerHtml = '<thead>';
                    innerHtml += '</thead><tbody>';
                    bodyLines.forEach(function (body, i) {
                        let style = 'color:' + body.color;
                        innerHtml += `<tr><td class="row"> <img class="img" src=${body.image}>` +
                            `<div style=${style}>` +
                            body.label +
                            `</div>` +
                            '</td></tr>';

                    });
                    innerHtml += '</tbody>';
                    const tableRoot = tooltipEl.querySelector('table');
                    if (changed) {
                        tableRoot.innerHTML = innerHtml;
                    }
                }
                //const positionY = this._chart.canvas.offsetTop;
                //const positionX = this._chart.canvas.offsetLeft;

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                //tooltipEl.style.left = positionX + tooltip.caretX + 'px';
                tooltipEl.style.left = this._eventPosition.x + "px";
                //tooltipEl.style.top = positionY + tooltip.caretY + 'px';
                tooltipEl.style.top = this._eventPosition.y + "px";
                tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
                tooltipEl.style.fontSize = '14px';
                tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
                tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
                tooltipEl.style.fontWeight = 'bold';
            }

        },
        legend: {
            display: false
        },
        scale: {
            ticks: {
                display: true,
                max: 10000,
                fontColor: 'black',
                backDropColor: 'black',
                callback: function (value, index, values) {
                    if (!_max) return '';
                    return _.round(((Math.pow(value, 3) * _max) / 1000), 2) + ' %'
                }
            },
            gridLines: {
                circular: true
            }
        }
    }
};
class RadarChartComponent extends Component {
    compareWith = 'country';
    constructor(props) {
        super(props);
        this.state = { selected: null, chartData, compareWithInfo: { type: '', name: '' } };
        subscribeToSelectedGeoObject(state => this.updateSelected(state.selected));
        subscribeToChartData(state => this.updateChartData(state));
    }

    async updateSelected(obj) {
        if (obj) {
            let selected = await dataSvc.getDataForGeoObject(obj);

            this.setState(selected);
        }
    }

    async updateChartData(state) {
        let incomplete;
        const _compareWith = {
            'municipality': async () => { return await dataSvc.getMunicipalityData(state.currentCompareWith.id) },
            'province': async () => { return await dataSvc.getProvinceData(state.currentCompareWith.id) },
            'region': async () => { return await dataSvc.getRegionData(state.currentCompareWith.id) },
            'country': async () => { return await dataSvc.getCountryData(state.currentCompareWith.id) },
        };

        if(!state.selected) return;

        let selectedDataSet = [
            state.selected.PRRPercent,
            state.selected.PAEWAMPercent,
            state.selected.UWAARPercent,
            state.selected.RTBMPercent,
            state.selected.DFTFPercent,
            state.selected.CTCJVPercent,
            state.selected.IDTPercent
        ];

        let compareDataSet = [];
        // ensure not comparing against self
        if (state.selected.id != state.currentCompareWith.id && state.currentCompareWith.type) {
            const compareData = await _compareWith[state.currentCompareWith.type]();
            if (!compareData) return;

            incomplete = compareData.incomplete;
            compareDataSet = [
                compareData.PRRPercent,
                compareData.PAEWAMPercent,
                compareData.UWAARPercent,
                compareData.RTBMPercent,
                compareData.DFTFPercent,
                compareData.CTCJVPercent,
                compareData.IDTPercent
            ];

        }

        let max = _.max([...selectedDataSet, ...compareDataSet]);
        _max = max;
        ///
        let _selectedDataSet = selectedDataSet.map(d => {
            return Math.pow(((d / max) || 0) * 1000, 1 / 3);
        })
        let _compareDataSet = compareDataSet.map(d => {
            return Math.pow(((d / max) || 0) * 1000, 1 / 3);
        })
        max = _.max([..._selectedDataSet, ..._compareDataSet]);
        ///
        datasets = [compareDataSet, selectedDataSet]

        this.setState({
            chartData: {
                datasets: this.state.chartData.datasets.map((ds, index) => {
                    return Object.assign(ds, {
                        label: index === 0 ? state.currentCompareWith.name : state.selected[`${state.selected.scopeType}Name`],
                        data: index === 0 ? !incomplete && _compareDataSet : _selectedDataSet
                    });
                }),
                options: Object.assign(this.state.chartData.options, {
                    scale: {
                        ticks: {
                            max: _.round(max == 0 ? 10 : max, 2),
                            min: 0,
                            stepSize: _.round(max / 4, 2)
                        }
                    }
                })
            }
        })
    }

    render() {
        return (
            <div className="radar-chart-wrapper">
                <Radar
                    data={this.state.chartData}
                    options={this.state.chartData.options}
                    height={232}
                    width={225}
                    datasetKeyProvider={() => { return Number(new Date()) + parseInt(Math.random() * 1000) }}
                />


                <div className="radar-icon regenerative" id="div-index0">
                    <div className="core" id="icon-index0">
                        <ReactSVG src={regenerativeIcon} svgStyle={{ width: 22, fill: '#FFFFFF' }}></ReactSVG>
                    </div>
                    <div className="tooltip" id="index0"> Prioritize<b> Regenerative<br />Resources</b></div>
                </div>

                <div className="radar-icon preserve">
                    <div className="core" id="icon-index1">
                        <ReactSVG src={preserveIcon} svgStyle={{ width: 22, fill: '#FFFFFF' }}></ReactSVG>
                    </div>
                    <div className="tooltip" id="index1"><b>Sustain and Preserve<br /></b> what's already there</div>
                </div>

                <div className="radar-icon waste">
                    <div className="core" id="icon-index2">
                        <ReactSVG src={wasteIcon} svgStyle={{ width: 22, fill: '#FFFFFF' }}></ReactSVG>
                    </div>
                    <div className="tooltip" id="index2"><b>Use Waste</b><br /> as a resource</div>
                </div>

                <div className="radar-icon rethink">
                    <div className="enabling" id="icon-index3">
                        <ReactSVG src={rethinkIcon} svgStyle={{ width: 22, fill: '#FFFFFF' }}></ReactSVG>
                    </div>
                    <div className="tooltip" id="index3"><b>Rethink the</b><br /> Business Model</div>
                </div>

                <div className="radar-icon design">
                    <div className="enabling" id="icon-index4">
                        <ReactSVG src={designIcon} svgStyle={{ width: 22, fill: '#FFFFFF' }}></ReactSVG>
                    </div>
                    <div className="tooltip" id="index4"><b>Design</b><br /> for the Future</div>
                </div>

                <div className="radar-icon collaborate">
                    <div className="enabling" id="icon-index5">
                        <ReactSVG src={collaborateIcon} svgStyle={{ width: 22, fill: '#FFFFFF' }}></ReactSVG>
                    </div>
                    <div className="tooltip" id="index5"> <b>Team up</b><br /> to create joint<br />value</div>
                </div>

                <div className="radar-icon digital">
                    <div className="enabling" id="icon-index6">
                        <ReactSVG src={digitalIcon} svgStyle={{ width: 22, fill: '#FFFFFF' }}></ReactSVG>
                    </div>
                    <div className="tooltip" id="index6"> Incorporate <b>Digital technology</b></div>
                </div>
            </div>
        );
    }
}

export default RadarChartComponent;