import axios from "axios";

const initialCompare = {
    id: 0,
    type: '',
    name: ''
};
let state = {
    selected: null,
    selectedDataSet: [],
    compareDataSet: [],
    currentCompareWith: initialCompare
};

const listeners = [];

export {
    getChartDataState,
    subscribeToChartData,
    updateDataSet,
    updateCompareWith
};

function getChartDataState() {
    return state;
}

async function updateDataSet(selected, currentCompareWith) {
    const _compareLevels = {
        region: [
            { id: selected.countryId, type: 'country', name: selected.countryName }
        ],
        province: [
            { id: selected.countryId, type: 'country', name: selected.countryName },
            { id: selected.regionId, type: 'region', name: selected.regionName }
        ],
        municipality: [
            { id: selected.countryId, type: 'country', name: selected.countryName },
            { id: selected.regionId, type: 'region', name: selected.regionName },
            { id: selected.provinceId, type: 'province', name: selected.provinceName }
        ]
    };

    //const _levels = ['country', 'region', 'province'];
    //const _levelWeight = { country: -1, region: 0, province: 1, municipality: 2 };
    state = Object.assign(state, { selected, compareWith: _compareLevels[selected.scopeType] });
    // while loading reset to initial compare
    updateCompareWith();
    let res = await axios.post('/api/compare', {type: selected.scopeType, id: selected.id});

    for(const _compare of res.data) {
        if(!_compare.incomplete) {
            return updateCompareWith({type: _compare.type, id: _compare.id, name: _compare.name})
        }
    }

    // if (_levelWeight[selected.scopeType] <= _levelWeight[state.currentCompareWith.type]) {
    //     updateCompareWith(_levels[_levelWeight[selected.scopeType]]);
    // } else {
    //     updateCompareWith(state.currentCompareWith.type);
    // }
}

function updateCompareWith(compare) {
    state = Object.assign(state, { currentCompareWith: compare ? compare : initialCompare });
    listeners.forEach(f => f(state));
}


function subscribeToChartData(func) {
    listeners.push(func);
}