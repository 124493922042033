import React, { Component } from 'react';
import _ from 'lodash';
import './InfoPane.css';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import RadarChart from './RadarChart/RadarChart';
import NumberFormat from 'react-number-format';
import ReactSVG from 'react-svg';
import * as numeral from 'numeral';
import DatatableComponent from './Datatable/Datatable';
import classNames from 'classnames';

import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import axios from 'axios';

import initiative1 from '../../../assets/initiative-1.png';
import initiative2 from '../../../assets/initiative-2.png';

import regenerativeIcon from '../../../assets/icons/regenerative.svg';
import preserveIcon from '../../../assets/icons/preserve.svg';
import rethinkIcon from '../../../assets/icons/rethink.svg';
import wasteIcon from '../../../assets/icons/waste.svg';
import collaborateIcon from '../../../assets/icons/collaborate.svg';
import designIcon from '../../../assets/icons/design.svg';
import digitalIcon from '../../../assets/icons/digital.svg';
import linkIcon from '../../../assets/icons/link.svg';
import printerIcon from '../../../assets/icons/printer.svg';
import close from '../../../assets/icons/close.png';
import arrowRight from '../../../assets/icons/arrow-right.png';
import arrowDown from '../../../assets/icons/arrow-down.png';

import selected from '../../../assets/selected.png';
import compareto from '../../../assets/compareto.png';
import { isMobile, BrowserView, MobileView } from "react-device-detect";

import { default as dataSvc } from "../../../services/mapDataSvc";
import { subscribeToSelectedGeoObject, getState, showOnMap, setAsMapScope, subscribeToShowInfo } from '../../../services/selectedGeoObject';
import { subscribeToChartData, getChartDataState, updateDataSet, updateCompareWith } from '../../../services/chartData';
import { onDispatchEvent } from '../../../services/events'
import { Object } from 'core-js';

class InfoPaneComponent extends Component {
  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state = {
      close: false,
      focus: false,
      selected: false,
      open: false,
      currentCompareWith: { type: '', name: '' },
      compareWithName: '',
      init: true,
      expanded: {
        'CTCJV': false,
        'DFTF': false,
        'IDT': false,
        'PAEWAM': false,
        'PRR': false,
        'RTBM': false,
        'UWAAR': false,
        'IDRC': false
      },
      searchResponse: [],
      cursor: 0
    };
  }

  componentWillMount = () => {
    //this.updateSelected(getState().selected);
    subscribeToSelectedGeoObject((state) => this.updateSelected(state));
    subscribeToShowInfo((state) => this.updateSelected(state));

    this.updateCompare(getChartDataState());
    subscribeToChartData(state => this.updateCompare(state));

    // subscribeToScopeData(state => this.scopeUpdated())

  }

  handleSearchQuery = (e) => {
    this.setState({ compareWithName: e.target.value.trim() });
    let searchQuery = e.target.value.trim();
    if (searchQuery) {
      axios.get(`/api/search?query=${searchQuery}`).then(res => {
        this.setState({ searchResponse: res.data, cursor: 0 });
      })
    } else {
      this.setState({ searchResponse: [] });
    }
  };

  onSelect(selectedItem) {
    updateCompareWith(selectedItem);
    this.setState({ currentCompareWith: selectedItem, compareWithName: selectedItem.name, focus: false });
  };

  handleKeyDown(e) {
    if (e.keyCode === 38 || e.keyCode === 40) e.preventDefault();

    const { cursor, searchResponse } = this.state;
    if (e.keyCode === 13 && searchResponse.length > 0) {
      this.onSelect(searchResponse[cursor]);
    }

    if (e.keyCode === 38 && cursor > 0) {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1
      }))
    } else if (e.keyCode === 40 && cursor < searchResponse.length - 1) {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }))
    }

    let searchListEl = document.querySelector('.compare-list');
    if (searchListEl) {
      searchListEl.scrollTop = searchListEl.clientHeight + 36 * (cursor - 9);
    }
  }

  static onShowOnTheMap() {
    showOnMap(getState().selected);
  }

  static onSetAsMapScope() {
    setAsMapScope(getState().selected, { updateScope: true });
  }

  async updateSelected(obj) {
    Object.keys(this.state.expanded)
      .filter(x => this.state.expanded[x])
      .forEach(x => this.expandSection(document.getElementsByClassName(x)[0]));

    this.setState({
      expanded: {
        'CTCJV': false,
        'DFTF': false,
        'IDT': false,
        'PAEWAM': false,
        'PRR': false,
        'RTBM': false,
        'UWAAR': false,
        'IDRC': false
      },
    })

    if (obj && obj.selected) this.setState({ init: false });
    let selected;
    selected = await dataSvc.getDataForGeoObject((obj && obj.selected) || { type: 'country', id: 1 });
    
    if (obj && obj.selected && selected.scopeType === 'municipality') {
      // get http reguest for sectors
      const datatable = await dataSvc.getSectorsForMunicipality(obj.selected.id);
      Object.assign(selected, { datatable });
    }

    //CHOR-30
    // if (obj && selected.scopeType !== 'country' && this.state[`${selected.scopeType}Name`] === selected[`${selected.scopeType}Name`]) return;

    updateDataSet(selected, this.state.currentCompareWith);
    this.setState(Object.assign(selected, { selected: true }));
  }

  updateCompare(state) {
    if (this._ismounted) {
      this.setState({ currentCompareWith: state.currentCompareWith, compareWith: state.compareWith, compareWithName: state.currentCompareWith.name });
    } else {
      this.setState(Object.assign(this.state, { currentCompareWith: state.currentCompareWith, compareWith: state.compareWith, compareWithName: state.currentCompareWith.name }));
    }    
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    this.setState({ open: false });
    if (event.target.attributes.name) {
      const compareType = event.target.attributes.name.nodeValue;
      updateCompareWith(compareType);
    }
  };


  collapseSection = (element) => {
    let sectionHeight = element.scrollHeight;
    let elementTransition = element.style.transition;
    element.style.transition = '';
    requestAnimationFrame(() => {
      element.style.height = sectionHeight + 'px';
      element.style.transition = elementTransition;
    });
    element.setAttribute('data-collapsed', 'true');
  }

  expandSection = (element) => {
    element.style.height = 53 + 'px';
    element.addEventListener('transitionend', (e) => {
      element.removeEventListener('transitionend', this.expandSection);
    });
    element.setAttribute('data-collapsed', 'false');
  }


  expand = (elementid) => {
    if (!this.state.datatable[elementid] || !this.state.datatable[elementid].length) {
      return
    }
    const expanded = this.state.expanded;
    let element = document.getElementsByClassName(elementid)[0];
    expanded[elementid] = !expanded[elementid];

    let isCollapsed = element.getAttribute('data-collapsed') === 'true';

    if (isCollapsed) {
      this.expandSection(element)
      element.setAttribute('data-collapsed', 'false')
    } else {
      this.collapseSection(element)
    }

    this.setState({ expanded });
  }

  // scopeUpdated() {
  //   const detailLevel = { 0: 'province', 2: 'region' }[currentScope()];
  //   if (this.state.selected && this.state.currentCompareWith.type !== detailLevel) {
  //     updateCompareWith({ 0: 'province', 2: 'region' }[currentScope()]);
  //   }
  // }

  onFocus = async () => {
    if (this.state.compareWithName) {
      await axios.get(`/api/search?query=${this.state.compareWithName}`).then(res => {
        this.setState({ searchResponse: res.data, cursor: 0 });
      })
    } else {
      this.setState({ searchResponse: [] });
    }
    this.setState({ focus: true })
  }

  onBlur = () => {
    this.setState({ focus: false, compareWithName: this.state.currentCompareWith.name, searchResponse: [] });
  }

  handleClick = () => {
    this.setState({ close: !this.state.close })
  }

  handleOpenShareLink = () => {
    this.props.handleOpenShareLink();
  }

  handleClose = () => {
    this.props.handleClose();
  }

  render() {
    return (<Card className={classNames('info-pane-wrapper', {'is-mobile': isMobile})}>
      {(
      <div className={classNames('', {hide: !this.state.selected})}>
        <div className="info-pane-block" style={{marginTop: isMobile ? '60px' : 0}}>
          <BrowserView>
            <div className="share-print-wrapper">
              <div className="share-link-wrapper has-label no-print" onClick={() => { this.handleOpenShareLink() }}>
                <div aria-label="Share">
                  <ReactSVG src={linkIcon} svgStyle={{width: 50, height: 25 }}/>
                </div>
              </div>

              <div className="print-wrapper has-label no-print" onClick={() => { onDispatchEvent('print', true) }}>
                <div aria-label="Print">
                    <ReactSVG src={printerIcon} svgStyle={{ width: 22, height: 22 }} />
                </div>
              </div>
            </div>
          </BrowserView>
          <MobileView>
            <div className="close-wrapper no-print" onClick={() => this.handleClose()}>
              <img src={close} style={{ width: 22, height: 22, position: 'fixed', top: '70px', right: '10px' }} />
            </div>
          </MobileView>
          <div className="info-pane_header">
            <div className="province">
              <div className="label">{(this.state.scopeType && this.state.scopeType.toUpperCase()) || 'MUNICIPALITY'}</div>
              <div className="province-name">{
                (this.state.scopeType && ((this.state.scopeType === 'country' && this.state.countryName) ||
                  (this.state.scopeType === 'region' && this.state.regionName) ||
                  (this.state.scopeType === 'province' && this.state.provinceName) ||
                  (this.state.scopeType === 'municipality' && this.state.municipalityName)))
                || (this.state.municipalityName || '-')
              }</div>
            </div>
          </div>


        <div className="circular-total-percent no-print">
          <div className="percent">
            {(this.state.totalCircularJobs / this.state.totalJobs * 100).toFixed(1)} %
        </div>
          <div className="circular-total">
            <div className="jobs">
              <div className="jobs-count">
                {numeral(this.state.totalCircularJobs).format('0,0')}
              </div>
              <div className="jobs-label">
                circular jobs
            </div>
            </div>
            <div className="jobs">
              <div className="jobs-count">
                {numeral(this.state.totalJobs).format('0,0')}
              </div>
              <div className="jobs-label">
                total jobs
            </div>
            </div>
          </div>
        </div>

        <div className="chart-wrapper">
          <div className="chart-margin">
            <RadarChart />
          </div>
          <div className="chart-legend no-print">
            <div className="legend-block legend-block-center">
              <img src={selected} alt={''} />
              <div className="legend-label">
                {
                  (this.state.scopeType && ((this.state.scopeType === 'country' && this.state.countryName) || (this.state.scopeType === 'region' && this.state.regionName) || (this.state.scopeType === 'province' && this.state.provinceName) || (this.state.scopeType === 'municipality' && this.state.municipalityName))) || (this.state.municipalityName || '-')
                }
              </div>
            </div>
            { this.state.currentCompareWith.id != 0 && // not loading
              this.state.id != this.state.currentCompareWith.id && // not comparing to self
              (<div className="legend-block">
                <img src={compareto} alt={''} />
                <div className="legend-select">
                  <ClickAwayListener onClickAway={this.onBlur}>
                    <div className="compareto-wrapper">
                      <InputBase className="input-compare"
                        disabled={!this.state.selected}
                        onFocus={this.onFocus}
                        value={this.state.compareWithName}
                        onChange={this.handleSearchQuery}
                        onKeyDown={this.handleKeyDown} />
                      {
                        this.state.compareWithName && this.state.focus &&
                        <div className="compare-list-wrapper">
                          <List component="nav" className="compare-list">
                            {
                              this.state.compareWith && <div className="parents">
                                {
                                  this.state.compareWith.map(c =>
                                    <ListItem className='compare-item compare-item-margin' key={`${c.type}_${c.id}`}
                                      onClick={() => this.onSelect(c)}>
                                      {c.name}&nbsp;<span className="type">{c.type}</span>
                                    </ListItem>
                                  )
                                }
                              </div>
                            }
                            {this.state.searchResponse &&
                              (this.state.searchResponse.map((r, i) =>
                                <ListItem className='compare-item' key={`${r.type}_${r.id}`}
                                  onClick={() => this.onSelect(r)}>
                                  {r.name}&nbsp;<span className="type">{r.type}</span>
                                </ListItem>))}

                            {(this.state.searchResponse && this.state.searchResponse.length === 0) &&
                              (<ListItem className="compare-item">
                                No results
                        </ListItem>)}
                          </List>
                        </div>
                      }
                    </div>
                  </ClickAwayListener>
                </div>
              </div>)
            }
          </div>
        </div>
      </div>
      <div className="divider no-print"></div>
      </div>)}
      {!this.state.selected && (
        <div className="info-pane-block not-selected">
          Select a <b>{this.props.currentDetailLevel}</b> to see details about circular jobs
        </div>
        
      )}


      <div className="info-pane-block">
        {
          this.state.datatable && <div className="jobs-info-wrapper no-print">
            <div className="wrapper">
              <div className="label">
                CIRCULAR JOBS
            </div>
              <div className="info-table">
                <div className="wrapper-block">
                  <div className="element-block">
                    <div className="element-visible" >
                      <div className="block-name bold blue">
                        Total
                    </div>
                      <div className="block-total bold">
                        <NumberFormat value={this.state.totalCircularJobs} displayType={'text'} thousandSeparator={true} />
                      </div>
                      <div className="block-percent padding">
                        {_.round((this.state.totalCircularJobs / this.state.totalJobs) * 100, 1) || '0'}%
                    </div>
                    </div>
                  </div>
                </div>

                <div className="wrapper-block">
                  <div className="element-block">
                    <div className="element-visible" >
                      <div className="block-name bold blue">
                        Core
                    </div>
                      <div className="block-total bold">
                        <NumberFormat value={this.state.totalCore} displayType={'text'} thousandSeparator={true} />
                      </div>
                      <div className="block-percent">
                        {_.round((this.state.totalCore / this.state.totalJobs) * 100, 1) || '0'}%
                    </div>
                    </div>
                  </div>

                  <div className="element-block PRR">
                    <div className="element-visible" onClick={() => this.expand('PRR')}>
                      <div className="block-icon">
                        {
                          this.state.datatable['PRR'] && this.state.datatable['PRR'].length && this.state.expanded['PRR'] && <img src={arrowDown} alt={''} />
                        }
                        {
                          this.state.datatable['PRR'] && this.state.datatable['PRR'].length && !this.state.expanded['PRR'] && <img src={arrowRight} alt={''} />
                        }
                      </div>
                      <div className="block-svg">
                        <ReactSVG src={regenerativeIcon} svgStyle={{ width: 24, fill: '#A0A8B1' }}></ReactSVG>
                      </div>
                      <div className="block-name">
                        Prioritize <span className="bold">Regenerative</span> Resources
                    </div>
                      <div className="block-total">
                        <NumberFormat value={this.state.PRR} displayType={'text'} thousandSeparator={true} />
                      </div>
                      <div className="block-percent">
                        {_.round((this.state.PRR / this.state.totalJobs) * 100, 1) || '0'}%
                      </div>
                    </div>
                    {
                      this.state.datatable['PRR'] &&
                      <div className="datatable-wrapper">
                        <DatatableComponent datatable={this.state.datatable['PRR']} />
                      </div>
                    }
                  </div>

                  <div className="element-block PAEWAM">
                    <div className="element-visible" onClick={() => this.expand('PAEWAM')}>
                      <div className="block-icon">
                        {
                          this.state.datatable['PAEWAM'] && this.state.datatable['PAEWAM'].length && this.state.expanded['PAEWAM'] && <img src={arrowDown} alt={''} />
                        }
                        {
                          this.state.datatable['PAEWAM'] && this.state.datatable['PAEWAM'].length && !this.state.expanded['PAEWAM'] && <img src={arrowRight} alt={''} />
                        }
                      </div>
                      <div className="block-svg">
                        <ReactSVG src={preserveIcon} svgStyle={{ width: 24, fill: '#A0A8B1' }}></ReactSVG>
                      </div>
                      <div className="block-name">
                        <span className="bold">Sustain and Preserve</span> What is Already There
                    </div>
                      <div className="block-total">
                        <NumberFormat value={this.state.PAEWAM} displayType={'text'} thousandSeparator={true} />
                      </div>
                      <div className="block-percent">
                        {_.round((this.state.PAEWAM / this.state.totalJobs) * 100, 1) || '0'}%
                      </div>
                    </div>
                    {
                      this.state.datatable['PAEWAM'] &&
                      <div className="datatable-wrapper">
                        <DatatableComponent datatable={this.state.datatable['PAEWAM']} />
                      </div>
                    }
                  </div>

                  <div className="element-block UWAAR">
                    <div className="element-visible" onClick={() => this.expand('UWAAR')} >
                      <div className="block-icon">
                        {
                          this.state.datatable['UWAAR'] && this.state.datatable['UWAAR'].length && this.state.expanded['UWAAR'] && <img src={arrowDown} alt={''} />
                        }
                        {
                          this.state.datatable['UWAAR'] && this.state.datatable['UWAAR'].length && !this.state.expanded['UWAAR'] && <img src={arrowRight} alt={''} />
                        }
                      </div>
                      <div className="block-svg">
                        <ReactSVG src={wasteIcon} svgStyle={{ width: 24, fill: '#A0A8B1' }}></ReactSVG>
                      </div>
                      <div className="block-name">
                        Use <span className="bold">Waste</span> as a Resource
                    </div>
                      <div className="block-total">
                        <NumberFormat value={this.state.UWAAR} displayType={'text'} thousandSeparator={true} />
                      </div>
                      <div className="block-percent">
                        {_.round((this.state.UWAAR / this.state.totalJobs) * 100, 1) || '0'}%
                      </div>
                    </div>
                    {
                      this.state.datatable['UWAAR'] &&
                      <div className="datatable-wrapper">
                        <DatatableComponent datatable={this.state.datatable['UWAAR']} />
                      </div>
                    }
                  </div>
                </div>

                <div className="wrapper-block">
                  <div className="element-block">
                    <div className="element-visible" >
                      <div className="block-name bold blue">
                        Enabling
                    </div>
                      <div className="block-total bold">
                        <NumberFormat value={this.state.totalEnabling} displayType={'text'} thousandSeparator={true} />
                      </div>
                      <div className="block-percent">
                        {_.round((this.state.totalEnabling / this.state.totalJobs) * 100, 1) || '0'}%
                    </div>
                    </div>
                  </div>

                  <div className="element-block RTBM">
                    <div className="element-visible" onClick={() => this.expand('RTBM')}>
                      <div className="block-icon">
                        {
                          this.state.datatable['RTBM'] && this.state.datatable['RTBM'].length && this.state.expanded['RTBM'] && <img src={arrowDown} alt={''} />
                        }
                        {
                          this.state.datatable['RTBM'] && this.state.datatable['RTBM'].length && !this.state.expanded['RTBM'] && <img src={arrowRight} alt={''} />
                        }
                      </div>
                      <div className="block-svg">
                        <ReactSVG src={rethinkIcon} svgStyle={{ width: 24, fill: '#A0A8B1' }}></ReactSVG>
                      </div>
                      <div className="block-name">
                        Rethink the <span className="bold">Business</span> Model
                    </div>
                      <div className="block-total">
                        <NumberFormat value={this.state.RTBM} displayType={'text'} thousandSeparator={true} />
                      </div>
                      <div className="block-percent">
                        {_.round((this.state.RTBM / this.state.totalJobs) * 100, 1) || '0'}%
                      </div>
                    </div>
                    {
                      this.state.datatable['RTBM'] &&
                      <div className="datatable-wrapper">
                        <DatatableComponent datatable={this.state.datatable['RTBM']} />
                      </div>
                    }
                  </div>

                  <div className="element-block DFTF">
                    <div className="element-visible" onClick={() => this.expand('DFTF')}>
                      <div className="block-icon">
                        {
                          this.state.datatable['DFTF'] && this.state.datatable['DFTF'].length && this.state.expanded['DFTF'] && <img src={arrowDown} alt={''} />
                        }
                        {
                          this.state.datatable['DFTF'] && this.state.datatable['DFTF'].length && !this.state.expanded['DFTF'] && <img src={arrowRight} alt={''} />
                        }
                      </div>
                      <div className="block-svg">
                        <ReactSVG src={designIcon} svgStyle={{ width: 24, fill: '#A0A8B1' }}></ReactSVG>
                      </div>
                      <div className="block-name">
                        <span className="bold">Design</span> for the Future
                    </div>
                      <div className="block-total">
                        <NumberFormat value={this.state.DFTF} displayType={'text'} thousandSeparator={true} />
                      </div>
                      <div className="block-percent">
                        {_.round((this.state.DFTF / this.state.totalJobs) * 100, 1) || '0'}%
                      </div>
                    </div>
                    {
                      this.state.datatable['DFTF'] &&
                      <div className="datatable-wrapper">
                        <DatatableComponent datatable={this.state.datatable['DFTF']} />
                      </div>
                    }
                  </div>

                  <div className="element-block CTCJV">
                    <div className="element-visible" onClick={() => this.expand('CTCJV')} >
                      <div className="block-icon">
                        {
                          this.state.datatable['CTCJV'] && this.state.datatable['CTCJV'].length && this.state.expanded['CTCJV'] && <img src={arrowDown} alt={''} />
                        }
                        {
                          this.state.datatable['CTCJV'] && this.state.datatable['CTCJV'].length && !this.state.expanded['CTCJV'] && <img src={arrowRight} alt={''} />
                        }
                      </div>
                      <div className="block-svg">
                        <ReactSVG src={collaborateIcon} svgStyle={{ width: 24, fill: '#A0A8B1' }}></ReactSVG>
                      </div>
                      <div className="block-name">
                        <span className="bold">Team up</span> to Create Joint Value Model
                    </div>
                      <div className="block-total">
                        <NumberFormat value={this.state.CTCJV} displayType={'text'} thousandSeparator={true} />
                      </div>
                      <div className="block-percent">
                        {_.round((this.state.CTCJV / this.state.totalJobs) * 100, 1) || '0'}%
                      </div>
                    </div>
                    {
                      this.state.datatable['CTCJV'] &&
                      <div className="datatable-wrapper">
                        <DatatableComponent datatable={this.state.datatable['CTCJV']} />
                      </div>
                    }
                  </div>

                  <div className="element-block IDT">
                    <div className="element-visible" onClick={() => this.expand('IDT')}>
                      <div className="block-icon">
                        {
                          this.state.datatable['IDT'] && this.state.datatable['IDT'].length && this.state.expanded['IDT'] && <img src={arrowDown} alt={''} />
                        }
                        {
                          this.state.datatable['IDT'] && this.state.datatable['IDT'].length && !this.state.expanded['IDT'] && <img src={arrowRight} alt={''} />
                        }
                      </div>
                      <div className="block-svg">
                        <ReactSVG src={digitalIcon} svgStyle={{ width: 24, fill: '#A0A8B1' }}></ReactSVG>
                      </div>
                      <div className="block-name">
                        Incorporate <span className="bold">Digital</span> technology
                    </div>
                      <div className="block-total">
                        <NumberFormat value={this.state.IDT} displayType={'text'} thousandSeparator={true} />
                      </div>
                      <div className="block-percent">
                        {_.round((this.state.IDT / this.state.totalJobs) * 100, 1) || '0'}%
                      </div>
                    </div>
                    {
                      this.state.datatable['IDT'] &&
                      <div className="datatable-wrapper">
                        <DatatableComponent datatable={this.state.datatable['IDT']} />
                      </div>
                    }
                  </div>
                </div>

                <div className="wrapper-block">
                  <div className="element-block IDRC">
                    <div className="element-visible" onClick={() => this.expand('IDRC')}>
                      <div className="block-icon">
                        {
                          this.state.datatable['IDRC'] && this.state.datatable['IDRC'].length && this.state.expanded['IDRC'] && <img src={arrowDown} alt={''} />
                        }
                        {
                          this.state.datatable['IDRC'] && this.state.datatable['IDRC'].length && !this.state.expanded['IDRC'] && <img src={arrowRight} alt={''} />
                        }
                      </div>
                      <div className="block-name bold blue">
                        Indirect
                    </div>
                      <div className="block-total bold">
                        <NumberFormat value={this.state.indirectJobs} displayType={'text'} thousandSeparator={true} />
                      </div>
                      <div className="block-percent">
                        {this.state.indirectJobsPercent || '0'}%
                    </div>
                    </div>
                    {
                      this.state.datatable['IDRC'] &&
                      <div className="datatable-wrapper">
                        <DatatableComponent datatable={this.state.datatable['IDRC']} />
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="initiative-wrapper wrapper">
              <div className="label">
                CIRCULAR INITIATIVES
            </div>
              <div className="initiatives-blocks">
                <div className="initiative-block">
                  <div className="initiative-img">
                    <img src={initiative1} alt={''} />
                  </div>
                  <div className="initiative-content">
                    <div className="initiative-title">
                      Antwerp, circular city of tomorrow
                  </div>
                    <div className="initiative-type">
                      policy implementation
                  </div>
                    <div className="initiative-description">
                      The research project 'Antwerp, the circular city of tomorrow' aims to generate new insights at the crossroads of urbanism, circular economy and spatial tasks in Antwerp.
                  </div>
                    <div className="initiative-readmore">
                      READ MORE >
                  </div>
                  </div>
                </div>
                <div className="initiative-block">
                  <div className="initiative-img">
                    <img src={initiative2} alt={''} />
                  </div>
                  <div className="initiative-content">
                    <div className="initiative-title">
                      WINPOL project
                  </div>
                    <div className="initiative-type">
                      policy implementation
                  </div>
                    <div className="initiative-description">
                      The WINPOL project aims at improving policies for waste management  through improved management procedures and awareness campaigns                </div>
                    <div className="initiative-readmore">
                      READ MORE >
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }




        <div className={classNames('jobs-info-wrapper_print', {'is-mobile': isMobile})}>
          <div className="left">
            <Table className="info-table top borderless">
              <TableBody>
                <TableRow>
                  <TableCell className="tc"><span className="bold">Total Jobs</span></TableCell>
                  <TableCell className="bold">
                    <NumberFormat value={this.state.totalJobs} displayType={'text'} thousandSeparator={true} />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="tc"><span className="bold blue">Total Circular Jobs</span></TableCell>
                  <TableCell className="bold">
                    <NumberFormat value={this.state.totalCircularJobs} displayType={'text'} thousandSeparator={true} />
                  </TableCell>
                  <TableCell className="td-percent">{_.round((this.state.totalCircularJobs / this.state.totalJobs) * 100, 1) || '0'}%</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Table className="info-table">
              <TableBody>
                <TableRow>
                  <TableCell className="td-icon"></TableCell>
                  <TableCell><span className="bold blue">Core</span></TableCell>
                  <TableCell>
                    <NumberFormat value={this.state.totalCore} displayType={'text'} thousandSeparator={true} />
                  </TableCell>
                  <TableCell>{_.round((this.state.totalCore / this.state.totalJobs) * 100, 1) || '0'}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="td-icon">
                    <ReactSVG src={wasteIcon} svgStyle={{ width: 24, fill: '#A0A8B1' }}></ReactSVG>
                  </TableCell>
                  <TableCell className="tc">Use <span className="bold">Waste</span> as<br />a Resource</TableCell>
                  <TableCell>
                    <NumberFormat value={this.state.UWAAR} displayType={'text'} thousandSeparator={true} />
                  </TableCell>
                  <TableCell className="td-percent">{this.state.UWAARPercent || '0'}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="td-icon">
                    <ReactSVG src={preserveIcon} svgStyle={{ width: 24, fill: '#A0A8B1' }}></ReactSVG>
                  </TableCell>
                  <TableCell className="tc"><span className="bold">Sustain and Preserve</span><br />What is Already There</TableCell>
                  <TableCell>
                    <NumberFormat value={this.state.PAEWAM} displayType={'text'} thousandSeparator={true} />
                  </TableCell>
                  <TableCell className="td-percent">{this.state.PAEWAMPercent || '0'}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="td-icon">
                    <ReactSVG src={regenerativeIcon} svgStyle={{ width: 24, fill: '#A0A8B1' }}></ReactSVG>
                  </TableCell>
                  <TableCell className="tc">Prioritize <span className="bold">Regenerative</span><br />Resources</TableCell>
                  <TableCell>
                    <NumberFormat value={this.state.PRR} displayType={'text'} thousandSeparator={true} />
                  </TableCell>
                  <TableCell className="td-percent">{this.state.PRRPercent || '0'}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="td-icon"></TableCell>
                  <TableCell className="tc"><span className="bold blue">Indirect</span></TableCell>
                  <TableCell>
                    <NumberFormat value={this.state.indirectJobs} displayType={'text'} thousandSeparator={true} />
                  </TableCell>
                  <TableCell className="td-percent">{this.state.indirectJobsPercent || '0'}%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="right">
            <Table className="info-table">
              <TableBody>
                <TableRow>
                  <TableCell className="td-icon"></TableCell>
                  <TableCell className="tc"><span className="bold blue">Enabling</span></TableCell>
                  <TableCell>
                    <NumberFormat value={this.state.totalEnabling} displayType={'text'} thousandSeparator={true} />
                  </TableCell>
                  <TableCell className="td-percent">{_.round((this.state.totalEnabling / this.state.totalJobs) * 100, 1) || '0'}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="td-icon">
                    <ReactSVG src={collaborateIcon} svgStyle={{ width: 24, fill: '#A0A8B1' }}></ReactSVG>
                  </TableCell>
                  <TableCell className="tc"><span className="bold">Team Up</span> to<br />Create Joint Value Model</TableCell>
                  <TableCell>
                    <NumberFormat value={this.state.CTCJV} displayType={'text'} thousandSeparator={true} />
                  </TableCell>
                  <TableCell className="td-percent">{this.state.CTCJVPercent || '0'}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="td-icon">
                    <ReactSVG src={digitalIcon} svgStyle={{ width: 24, fill: '#A0A8B1' }}></ReactSVG>
                  </TableCell>
                  <TableCell className="tc">Incorporate <span className="bold">Digital</span><br />technology</TableCell>
                  <TableCell>
                    <NumberFormat value={this.state.IDT} displayType={'text'} thousandSeparator={true} />
                  </TableCell>
                  <TableCell className="td-percent">{this.state.IDTPercent || '0'}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="td-icon">
                    <ReactSVG src={designIcon} svgStyle={{ width: 24, fill: '#A0A8B1' }}></ReactSVG>
                  </TableCell>
                  <TableCell className="tc"><span className="bold">Design</span> for the<br />Future</TableCell>
                  <TableCell>
                    <NumberFormat value={this.state.DFTF} displayType={'text'} thousandSeparator={true} />
                  </TableCell>
                  <TableCell className="td-percent">{this.state.DFTFPercent || '0'}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="td-icon">
                    <ReactSVG src={rethinkIcon} svgStyle={{ width: 24, fill: '#A0A8B1' }}></ReactSVG>
                  </TableCell>
                  <TableCell className="tc">Rethink the <span className="bold"><br />Business</span> Model</TableCell>
                  <TableCell>
                    <NumberFormat value={this.state.RTBM} displayType={'text'} thousandSeparator={true} />
                  </TableCell>
                  <TableCell className="td-percent">{_.round((this.state.RTBM / this.state.totalJobs) * 100, 1) || '0'}%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </Card>)
  }
}

export default InfoPaneComponent