import React, { Component } from "react";
import open from '../../../assets/icons/open.svg';
import Typography from '@material-ui/core/Typography';
import ReactSVG from 'react-svg'

function LearnMore({url}) {
    return <Typography 
        variant="button"
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
            flexWrap: 'wrap',
            marginTop: '10px',
            cursor: 'pointer'
        }}
        onClick={() => url ? window.open(url, "_blank") : ''}
        >
         Learn More
        <span>
            <ReactSVG src={open} svgStyle={{ paddingLeft:5, width: 16, height: 16, fill: '#1187B3' }} />
        </span>
    </Typography>
}

export default LearnMore;