import axios from "axios";
import _ from "lodash";

const cache = {};
const geojsonCache = {};

export default {
  getMapData,
  getFeaturePopup,
  getSelectedItem,
  getDataForGeoObject,
  getMunicipalityData,
  getProvinceData,
  getRegionData,
  getCountryData,
  getCountriesList,
  getRegionsList,
  getProvincesList,
  processData,
  getSectorsForMunicipality
};

function getSelectedItem(feature) {
  const type = feature.featureType || feature.detailLevel;
  if (type == 'municipality') {
    return {
      id: feature.id || feature[`${type}Id`],
      name: feature.municipalityName,
      type: feature.detailLevel,
      countryId: feature.countryId,
      countryName: feature.countryName,
      originalType: feature.featureType
    };
  }

  if (type == 'province') {
    return {
      id: feature.id || feature[`${type}Id`],
      name: feature[`${type}Name`],
      type: feature.detailLevel,
      originalType: feature.featureType
    };
  }

  if (type == 'region') {
    return {
      id: feature.id || feature[`${type}Id`],
      name: feature[`${type}Name`],
      type: feature.detailLevel,
      originalType: feature.featureType
    };
  }

  return {
    id: feature.id || feature[`${type}Id`],
    name: feature[`${type}Name`],
    type: feature.detailLevel,
    originalType: feature.featureType
  };
}

function numberWithCommas(number){
  if(!number) return 0;
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getFeaturePopup(detailLevel, jobsType, usePercent, feature, jobsSubType) {
  if(feature.featureType) {
    detailLevel = feature.featureType;
  }
  let jobsValue;
  if(typeof jobsSubType !== 'undefined') {
    let _value = {
      'core': {
        0: 'PRR',
        1: 'PAEWAM',
        2: 'UWAAR',
      },
      'enabling': {
        0: 'RTBM',
        1: 'CTCJV',
        2: 'DFTF',
        3: 'IDT'
      }
    }[jobsType][jobsSubType];
    _value = usePercent ? _value + 'Percent' : _value;
    jobsValue = feature[_value]
  } else {
    jobsValue = usePercent ? feature[jobsType].percent : feature[jobsType].value;
  }

  const jobsFormated = usePercent ? `${jobsValue}% jobs` : `${numberWithCommas(jobsValue)} jobs`;
  
  if (detailLevel === "municipality") {
    return `
            <Card class="hover-balloon">
                <div class="title-name">${feature.municipalityName}</div>
                <div class="subtitle-name">${feature.provinceName}</div>
                <div class="jobs">${jobsFormated}</div>
            </Card>`;
  }

  if (detailLevel === "province") {
    return `
            <Card class="hover-balloon">
                <div class="title-name">${feature.provinceName}</div>
                <div class="subtitle-name">${feature.regionName}</div>
                <div class="jobs">${jobsFormated}</div>
            </Card>`;
  }

  if (detailLevel === "region") {
    return `
            <Card class="hover-balloon">
                <div class="title-name">${feature.regionName}</div>
                <div class="subtitle-name">${feature.countryName}</div>
                <div class="jobs">${jobsFormated}</div>
            </Card>`;
  }

  if (detailLevel === "country") {
    return `
            <Card class="hover-balloon">
                <div class="title-name">${feature.countryName}</div>
                <div class="jobs">${jobsFormated}</div>
            </Card>`;
  }

  throw new Error("Cannot find popup");
}

async function getMapData(detailLevel) {
  const apiUrl = {
    "municipality": '/api/municipalities',
    "province": "/api/provinces",
    "region": "/api/regions",
    "country": "/api/countries"
  }[detailLevel];

  const mapData = cache[detailLevel] ? cache[detailLevel] : cache[detailLevel] = (await axios.get(apiUrl)).data;
  return await mergeWithGeoJson(detailLevel, processData(mapData, detailLevel));
}

function getSectorsForMunicipality(municipalityid) {
  return axios.get(`/api/municipalities-sectors/${municipalityid}`).then(res => {
    return res.data[municipalityid];
  });
}

async function getDataForGeoObject(selected) {
  let data = (await getMapData(selected.originalType || selected.type))
    .data
    .data
    .find(d =>  d.id === selected.id);

    if(!data) {
    data = (await getMapData(selected.type))
    .data
    .data
    .find(d => d.id === selected.id);
    }

    return Object.assign(data, {scopeType: selected.originalType || selected.type});
}

async function mergeWithGeoJson(detailLevel, data) {
  const sameGeoObject = (feature, data) => (feature.uniqId === getUniqId(detailLevel, data));

  const apiUrl = {
    "municipality": '/api/geojson/municipalities',
    "province": "/api/geojson/provinces",
    "region": "/api/geojson/regions",
    "country": "/api/geojson/countries"
  }[detailLevel];
  
  const geojson = geojsonCache[detailLevel] || await axios.get(apiUrl).then(res => geojsonCache[detailLevel] = res.data).catch(err => console.error(err));
  
  if(!geojson) return  {geoJSON: null, data: {}};

  geojson.features.map(feature => (Object.assign(feature, {data: true})));

  return {
    geoJSON: geojson, data
  };
}

function processData(data, detailLevel) {
  if(!data || !data.length) return [];

  const minTotal = _.minBy(data, i => i.totalCircularJobs).totalCircularJobs;
  const maxTotal = _.maxBy(data, i => i.totalCircularJobs).totalCircularJobs;
  const minTotalPercent = _.min(_.map(data, i => _.round((i.totalCircularJobs / i.totalJobs) * 100, 2)));
  const maxTotalPercent = _.max(_.map(data, i => _.round((i.totalCircularJobs / i.totalJobs) * 100, 2)));

  const minCore = _.minBy(data, i => i.totalCore).totalCore;
  const maxCore = _.maxBy(data, i => i.totalCore).totalCore;
  const minCorePercent = _.min(_.map(data, i => _.round((i.totalCore / i.totalJobs) * 100, 2)));
  const maxCorePercent = _.max(_.map(data, i => _.round((i.totalCore / i.totalJobs) * 100, 2)));

  //regenerative
  const minPRR = _.minBy(data, i => i.PRR).PRR;
  const maxPRR = _.maxBy(data, i => i.PRR).PRR;
  const minPRRPercent = _.min(_.map(data, i => _.round((i.PRR / i.totalJobs) * 100, 2)));
  const maxPRRPercent = _.max(_.map(data, i => _.round((i.PRR / i.totalJobs) * 100, 2)));

  //rethink
  const minRTBM = _.minBy(data, i => i.RTBM).RTBM;
  const maxRTBM = _.maxBy(data, i => i.RTBM).RTBM;
  const minRTBMPercent = _.min(_.map(data, i => _.round((i.RTBM / i.totalJobs) * 100, 2)));
  const maxRTBMPercent = _.max(_.map(data, i => _.round((i.RTBM / i.totalJobs) * 100, 2)));

  //preserve
  const minPAEWAM = _.minBy(data, i => i.PAEWAM).PAEWAM;
  const maxPAEWAM = _.maxBy(data, i => i.PAEWAM).PAEWAM;
  const minPAEWAMPercent = _.min(_.map(data, i => _.round((i.PAEWAM / i.totalJobs) * 100, 2)));
  const maxPAEWAMPercent = _.max(_.map(data, i => _.round((i.PAEWAM / i.totalJobs) * 100, 2)));

  //waste
  const minUWAAR = _.minBy(data, i => i.UWAAR).UWAAR;
  const maxUWAAR = _.maxBy(data, i => i.UWAAR).UWAAR;
  const minUWAARPercent = _.min(_.map(data, i => _.round((i.UWAAR / i.totalJobs) * 100, 2)));
  const maxUWAARPercent = _.max(_.map(data, i => _.round((i.UWAAR / i.totalJobs) * 100, 2)));


  const minEnabling = _.minBy(data, i => i.totalEnabling).totalEnabling;
  const maxEnabling = _.maxBy(data, i => i.totalEnabling).totalEnabling;
  const minEnablingPercent = _.min(_.map(data, i => _.round((i.totalEnabling / i.totalJobs) * 100, 2)));
  const maxEnablingPercent = _.max(_.map(data, i => _.round((i.totalEnabling / i.totalJobs) * 100, 2)));

  //collaborate
  const minCTCJV = _.minBy(data, i => i.CTCJV).CTCJV;
  const maxCTCJV = _.maxBy(data, i => i.CTCJV).CTCJV;
  const minCTCJVPercent = _.min(_.map(data, i => _.round((i.CTCJV / i.totalJobs) * 100, 2)));
  const maxCTCJVPercent = _.max(_.map(data, i => _.round((i.CTCJV / i.totalJobs) * 100, 2)));

  //digital
  const minIDT = _.minBy(data, i => i.IDT).IDT;
  const maxIDT = _.maxBy(data, i => i.IDT).IDT;
  const minIDTPercent = _.min(_.map(data, i => _.round((i.IDT / i.totalJobs) * 100, 2)));
  const maxIDTPercent = _.max(_.map(data, i => _.round((i.IDT / i.totalJobs) * 100, 2)));

  //design
  const minDFTF = _.minBy(data, i => i.DFTF).DFTF;
  const maxDFTF = _.maxBy(data, i => i.DFTF).DFTF;
  const minDFTFPercent = _.min(_.map(data, i => _.round((i.DFTF / i.totalJobs) * 100, 2)));
  const maxDFTFPercent = _.max(_.map(data, i => _.round((i.DFTF / i.totalJobs) * 100, 2)));

  const minIndirect = _.minBy(data, i => i.indirectJobs).indirectJobs;
  const maxIndirect = _.maxBy(data, i => i.indirectJobs).indirectJobs;
  const minIndirectPercent = _.min(_.map(data, i => _.round((i.indirectJobs / i.totalJobs) * 100, 2)));
  const maxIndirectPercent = _.max(_.map(data, i => _.round((i.indirectJobs / i.totalJobs) * 100, 2)));

  const getId = {
    "municipality": (d) => d.id,
    "province": (d) => d.provinceId,
    "region": (d) => d.regionId,
    "country": (d) => d.countryId
  }[detailLevel];

  return {
    data: _.map(data, (i) => ({
      ...i,
      id: i.municipalityId || i.provinceId || i.regionId || i.countryId,
      total: {
        value: i.totalCircularJobs,
        percent: _.round((i.totalCircularJobs / i.totalJobs) * 100, 1)
      },
      core: {
        value: i.totalCore,
        percent: _.round((i.totalCore / i.totalJobs) * 100, 1),
      },
      enabling: {
        value: i.totalEnabling,
        percent: _.round((i.totalEnabling / i.totalJobs) * 100, 1)
      },
      indirect: {
        value: i.indirectJobs,
        percent: _.round((i.indirectJobs / i.totalJobs) * 100, 1)
      },
      indirectJobs: i.indirectJobs,
      indirectJobsPercent: _.round((i.indirectJobs / i.totalJobs) * 100, 2) || 0,
      CTCJVPercent: _.round((i.CTCJV / i.totalJobs) * 100, 2) || 0,
      DFTFPercent: _.round((i.DFTF / i.totalJobs) * 100, 2) || 0,
      IDTPercent: _.round((i.IDT / i.totalJobs) * 100, 2) || 0,
      PAEWAMPercent: _.round((i.PAEWAM / i.totalJobs) * 100, 2) || 0,
      PRRPercent: _.round((i.PRR / i.totalJobs) * 100, 2) || 0,
      RTBMPercent: _.round((i.RTBM / i.totalJobs) * 100, 2) || 0,
      UWAARPercent: _.round((i.UWAAR / i.totalJobs) * 100, 2) || 0
    })),
    total: {
      min: minTotal,
      max: maxTotal,
      minPercent: minTotalPercent,
      maxPercent: maxTotalPercent
    },
    core: {
      min: minCore,
      max: maxCore,
      minPercent: minCorePercent,
      maxPercent: maxCorePercent,
      subCore: {
        minPRR,
        maxPRR,
        minPRRPercent,
        maxPRRPercent,

        minPAEWAM,
        maxPAEWAM,
        minPAEWAMPercent,
        maxPAEWAMPercent,

        minUWAAR,
        maxUWAAR,
        minUWAARPercent,
        maxUWAARPercent
      }
    },
    enabling: {
      min: minEnabling,
      max: maxEnabling,
      minPercent: minEnablingPercent,
      maxPercent: maxEnablingPercent,
      subEnabling: {
        minRTBM,
        maxRTBM,
        minRTBMPercent,
        maxRTBMPercent,
        
        minCTCJV,
        maxCTCJV,
        minCTCJVPercent,
        maxCTCJVPercent,

        minIDT,
        maxIDT,
        minIDTPercent,
        maxIDTPercent,

        minDFTF,
        maxDFTF,
        minDFTFPercent,
        maxDFTFPercent
      }
    },
    indirect: {
      min: minIndirect,
      max: maxIndirect,
      minPercent: minIndirectPercent,
      maxPercent: maxIndirectPercent
    },
  };
}
async function getMunicipalityData(municipalityId) {
  return (await this.getMapData('municipality')).data.data.find(p => p.municipalityId === municipalityId);
}
async function getProvinceData(provinceId) {
  return (await this.getMapData('province')).data.data.find(p => p.provinceId === provinceId);
}
async function getRegionData(regionId) {
  return (await this.getMapData('region')).data.data.find(p => p.regionId === regionId);
}
async function getCountryData(countryId) {
  return (await this.getMapData('country')).data.data.find(p => p.countryId === countryId);
}

async function getCountriesList() {
  let countryMapData = (await this.getMapData('country'));
  if(!countryMapData.data || !countryMapData.data.data.length) return [];
  return countryMapData.data.data.map(d => {return {
    id: d.countryId,
    name: d.countryName
  }});
}

async function getRegionsList() {
  let regionMapData = (await this.getMapData('region'));
  if(!regionMapData.data || !regionMapData.data.data.length) return [];

  return regionMapData.data.data.map(d => {return {
    id: d.regionId,
    name: d.regionName,
    countryId: d.countryId,
    countryName: d.countryName
  }});
}

async function getProvincesList() {
  let provinceMapData = (await this.getMapData('province'));
  if(!provinceMapData.data || !provinceMapData.data.data.length) return [];

  return provinceMapData.data.data.map(d => {return {
    id: d.provinceId,
    name: d.provinceName,
    regionId: d.regionId,
    regionName: d.regionName,
    countryId: d.countryId,
    countryName: d.countryName
  }});
}

function getUniqId(detailLevel, data) {
  // uniqId is first uppercase letter of area type (country/region/province or municipality) + `_` + .id
  return `${detailLevel[0].toUpperCase()}_${data[detailLevel + 'id']}`
}