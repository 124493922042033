import React, { Component } from 'react';
import './MobileInfoPane.css';
import InfoPaneComponent from '../../InfoPane/InfoPane'
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { onEventSubscribe, onDispatchEvent } from '../../../../services/events';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class MobileInfoPaneComponent extends Component {
  state = {
    open: false
  }
  constructor(props) {
    super(props);

    onEventSubscribe((state) => {
      this.setState({open: state.openInfoPane}, () => {
        const dialogContainerEl = document.querySelector('.MuiDialog-container');
        dialogContainerEl.setAttribute('tabindex', '');
      });
    })
  }

  handleClose = () => {
    this.setState({open: false});
    onDispatchEvent('openInfoPane', false)
  }


  render() {
    const { open } = this.state;
    return (
      <div className="info-pane-wrapper_mobile">
        <Dialog
          className="info-pane-wrapper_dialog"
          open={open}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          onClose={this.handleClose}
          hideBackdrop
          disableBackdropClick
          >
          <InfoPaneComponent currentDetailLevel={this.props.currentDetailLevel} handleClose={this.handleClose}/>
          <div className="close-button" onClick={this.handleClose}>
            <div className="mcb">
              <div className="cb"></div>
            </div>
          </div>
        </Dialog>
      </div>)
  }
}

export default MobileInfoPaneComponent