import React, { Component } from 'react';
import './MobileInfoPopup.css';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import Slide from '@material-ui/core/Slide';
import { subscribeToSelectedGeoObject, subscribeToShowInfo } from '../../../../services/selectedGeoObject';
import { default as dataSvc } from "../../../../services/mapDataSvc";
import { onEventSubscribe, onDispatchEvent } from '../../../../services/events';
import NumberFormat from 'react-number-format';
import ReactSVG from 'react-svg';


import regenerativeIcon from '../../../../assets/icons/regenerative.svg';
import preserveIcon from '../../../../assets/icons/preserve.svg';
import wasteIcon from '../../../../assets/icons/waste.svg';
import rethinkIcon from '../../../../assets/icons/rethink.svg';
import designIcon from '../../../../assets/icons/design.svg';
import collaborateIcon from '../../../../assets/icons/collaborate.svg';
import digitalIcon from '../../../../assets/icons/digital.svg';

class MobileInfoPopupComponent extends Component {
  constructor(props) {
    super(props);
    subscribeToSelectedGeoObject((state) => {if(state.selected) this.updateSelected(state)});
    subscribeToShowInfo(state => this.updateSelected(state))

    this.state = {
        open: false,
        jobsType: 'total',
        jobsSubType: [],
        title: 'All Circular Jobs',
        icon: rethinkIcon,
        jobsNumber: 0,
        jobsNumberPercent: 0,
        selectedName: '',
        selectedParentName: ''
        
    };

    onEventSubscribe(e => e.filter && this.updateFilter(e.filter));
  }

  updateSelected = async (state) => {
    let selected = await dataSvc.getDataForGeoObject((state && state.selected) || { type: 'country', id: 1 });
    this.setState(Object.assign(selected, {
      selectedName: selected[`${selected.scopeType}Name`],
      selectedParentName: selected[`${{region: 'country', province: 'region', municipality: 'province'}[selected.scopeType]}Name`],
      open: true,
      selected: true
    }), () => this.updateData());
  }

  updateFilter = filter => {
    this.setState({jobsType: filter.jobsType, jobsSubType: filter.jobsSubType }, () => this.updateData());
  }

  updateData = () => {
    let jobsTypeTitle = '', icon = '', jobsNumber = 0, jobsNumberPercent = 0;
    if(this.state.selectedName) {
      switch(this.state.jobsType) {
        case 'total':
          if(!this.state.jobsSubType.length || this.state.jobsSubType.length > 1) {
            jobsTypeTitle = 'All Circular Jobs';
            jobsNumber = this.state.total.value;
            jobsNumberPercent = this.state.total.percent;
            //
            icon = rethinkIcon;
          }
          break;
        case 'core':
          if(!this.state.jobsSubType.length || this.state.jobsSubType.length > 1) {
            jobsTypeTitle = 'Core Circular Jobs';
            jobsNumber = this.state.core.value;
            jobsNumberPercent = this.state.core.percent;
            //
            icon = rethinkIcon;
          } else {
            const i = this.state.jobsSubType[0];
            jobsTypeTitle = [
              "Prioritize <b>Regenerative</b> Resources",
              "<b>Sustain and Preserve</b> What's Already There",
              "Use <b>Waste</b>  as a Resource"
            ][i];
            jobsNumber = this.state[[
              'PRR',
              'PAEWAM',
              'UWAAR'
            ][i]];
            jobsNumberPercent = this.state[[
              'PRRPercent',
              'PAEWAMPercent',
              'UWAARPercent'
            ][i]];
            icon = [
              regenerativeIcon,
              preserveIcon,
              wasteIcon
            ][i];
          }
          break;
        case 'enabling':
          if(!this.state.jobsSubType.length || this.state.jobsSubType.length > 1) {
            jobsTypeTitle = 'Enabling Circular Jobs';
            jobsNumber = this.state.enabling.value;
            jobsNumberPercent = this.state.enabling.percent;
            //
            icon = rethinkIcon;
          } else {
            const i = this.state.jobsSubType[0];
            jobsTypeTitle = [
              "Rethink the <b>Business</b> Model",
              "<b>Design</b> for the Future",
              "<b>Team up</b> to Create Joint Value",
              "Incorporate <b>Digital</b> technology"
            ][i];
            jobsNumber = this.state[[
              'RTBM',
              'DFTF',
              'CTCJV',
              'IDT'
            ][i]];
            jobsNumberPercent = this.state[[
              'RTBMPercent',
              'DFTFPercent',
              'CTCJVPercent',
              'IDTPercent'
            ][i]];
            icon = [
              rethinkIcon,
              designIcon,
              collaborateIcon,
              digitalIcon
            ][i];
          }
          break;
        case 'indirect':
          if(!this.state.jobsSubType.length || this.state.jobsSubType.length > 1) {
            jobsTypeTitle = 'Indirectly Circular Jobs';
            jobsNumber = this.state.indirect.value;
            jobsNumberPercent = this.state.indirect.percent;
            //
            icon = rethinkIcon;
          }
          break;
        default:
          break;
      }
    }

    this.setState({title: jobsTypeTitle, icon, jobsNumber, jobsNumberPercent });
  }

  handleClose = () => {
    this.setState({open: false});
    this.props.handleMobilePopupClose();
  }


  render() {
    const { selectedName, selectedParentName } = this.state;
    return (
    <div className={classNames('info-popup-wrapper_mobile')}>
        <Slide direction="up" in={this.state.open} mountOnEnter unmountOnExit>
          <div>
            <div className="close-button" onClick={(e) => this.handleClose()}>
              <div className="mcb">
                <div className="cb"></div>
              </div>
            </div>
            <Card className="popup-content" onClick={() => onDispatchEvent('openInfoPane', true)}>
              <div className="selected-place-wrapper">
                <div className="name">{selectedName}</div>
                <div className="parent-name">{selectedParentName || 'World'}</div>
              </div>
              <div className="jobs-info-wrapper">
                <div className="block-svg">
                  <ReactSVG src={this.state.icon} svgStyle={{ width: 24, fill: '#A0A8B1' }}></ReactSVG>
                </div>
                <div className="jobs-title" dangerouslySetInnerHTML={{__html: this.state.title}}>
                </div>
                <div className="jobs-data">
                  <div className="total">
                    <b><NumberFormat value={this.state.jobsNumber} displayType={'text'} thousandSeparator={true} /> jobs</b>
                  </div>
                  <div className="total-percent">
                    {this.state.jobsNumberPercent}% of all jobs
                  </div>
                </div>
              </div>
            </Card>
            <div className="decorative-line"></div>
          </div>
      </Slide>
    </div>)
  }
}

export default MobileInfoPopupComponent