import React from 'react'
import './ShareLink.css'
import { Dialog, DialogContent, InputBase, Checkbox, FormControlLabel, Typography, withStyles, Box, Popover } from '@material-ui/core';
import { subscribeToShowInfo, subscribeToSelectedGeoObject } from '../../../services/selectedGeoObject'
import copyToClipboard from 'copy-to-clipboard';

import closeIcon from '../../../assets/icons/close.svg';
import copyIcon from '../../../assets/icons/copy.svg';

import ReactSVG from 'react-svg'

const styles = theme => ({
    root: {
        color: '#e8e8e8',
        '&$checked': {
            color: '#3876B7',
        },
    },
    checked: {},
    colorSwitchBase: {
        color: '#3876B7',
        '&$colorChecked': {
            color: '#3876B7',
            '& + $colorBar': {
                backgroundColor: '#9D9D9D'
            }
        }
    }
});


class ShareLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            anchorEl: null,
            selected: {},
            link: null,
            copiedPopupShow: false,
            setScope: false
        }
    }
    
    componentWillReceiveProps = (nextProps) => {
        this.setState({ open: nextProps.open, value: nextProps.tab });
    }

    componentWillMount = () => {
        const updated = (state) => {
            if(!state.selected) return;
            const {id, name, originalType, type} = state.selected || {};
            const scoped = state.scoped || {};
            this.setState({selected: state.selected, link: `${window.location.origin}/${originalType || type}/${id}/${name.replace(/\s/g, '-')}${(id === scoped.id && originalType || type === scoped.type) ? '?set-scope=true' : ''}`,
                            setScope: id === scoped.id && originalType || type === scoped.type});
        }

        subscribeToShowInfo(updated);
        subscribeToSelectedGeoObject(updated)
    }

    handleClose = () => {
        this.props.openShare({ open: false });
        //this.setState(state => ({link: state.link.replace(/\?set-scope=(true|false)/, '')}));
        const active = document.querySelectorAll('.filter-block.is-active');
        if (active && active.length) {
            active[0].classList.remove('is-active');
        }
    };

    setScope = () => {
        this.setState(state => ({link: state.link.indexOf('set-scope') > -1 ? state.link.replace(/\?set-scope=(true|false)/, '') : state.link + '?set-scope=true', setScope: !state.setScope}))
    }

    handleCopy = (event) => {
        copyToClipboard(this.state.link);
        this.setState({anchorEl: event.currentTarget, copiedPopupShow: true});
        setTimeout(() => this.setState({copiedPopupShow: false}), 500);
    }


    render() {
        const { classes } = this.props;
        const { link, anchorEl, open, copiedPopupShow, setScope } = this.state;
        const { id, originalType, type, name } = this.state.selected || {};
        
        return (
            <Dialog
                className="dialog"
                open={open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'md'}
            >
                <DialogContent className="share-link-dialog">
                    <p className="header-title">Link to {originalType || type} <b>{name}</b></p>

                    <div className="link-wrapper">
                        <InputBase type="text" readOnly value={link} />
                        <span className="copy-button" onClick={this.handleCopy}><ReactSVG src={copyIcon} svgStyle={{ width: 24, height: 24 }}  /></span>
                        <Popover
                                open={copiedPopupShow}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                                }}
                                transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                                }}
                                style={{ zIndex: 9999 }}
                            >
                                <Box p={2}>
                                <p>coppied!</p>
                                </Box>
                        </Popover>
                    </div>

                    <div className="scope-checkbox-wrapper">
                        <FormControlLabel
                            control={
                                <Checkbox
                                checked={setScope}
                                onClick={() => this.setScope()}
                                    classes={{
                                        root: classes.root,
                                        checked: classes.checked
                                    }}
                                />
                            }
                            label={<Typography style={{ color: '#656565', fontWeight: 'bold', fontFamily: 'Montserrat', lineHeight: '25px', fontSize: '12px' }}>set scope</Typography>} />
                    </div>

                </DialogContent>
                <div className="close-btn" onClick={this.handleClose}><ReactSVG src={closeIcon} svgStyle={{ width: 12, height: 12 }} /></div>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ShareLink);